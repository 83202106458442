import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SkeletonTipoIncidencia from '../SkeletonTipoIncidencia';

const Step1 = ({ tiposIncidencia, setNuevaIncidencia, nuevaIncidencia, setStepModal }) => {
    const { t } = useTranslation();
    return (
        <>
            <p className='modal-nueva-incidencia__subtitle'>{t('area-personal.incidencias.modals.nueva-incidencia.motivo')}</p>
            <section className='modal-nueva-incidencia__incidencias'>
                {tiposIncidencia
                    ? tiposIncidencia?.map(({ id }) =>
                        <div key={id}
                            onClick={() => setNuevaIncidencia({ ...nuevaIncidencia, tipo: id })}
                            className={`modal-nueva-incidencia__incidencias--each-container ${nuevaIncidencia?.tipo === id ? 'tipo-active' : ''}`}
                        >
                            <h4>{t(`area-personal.incidencias.modals.nueva-incidencia.${id}.title`)}</h4>
                            <p>{t(`area-personal.incidencias.modals.nueva-incidencia.${id}.text`)}</p>
                        </div>)
                    : <><SkeletonTipoIncidencia/><SkeletonTipoIncidencia/><SkeletonTipoIncidencia/><SkeletonTipoIncidencia/><SkeletonTipoIncidencia/><SkeletonTipoIncidencia/></>}
            </section>
            <div className='modal-nueva-incidencia__btn-container'>
                <button
                    className='modal-nueva-incidencia__btn-container--btn'
                    disabled={!nuevaIncidencia?.tipo}
                    onClick={() => setStepModal(2)}>
                    {t('area-personal.incidencias.modals.nueva-incidencia.siguiente')}
                </button>
            </div>
        </>
    );
};

Step1.propTypes = {
    tiposIncidencia: PropTypes.any,
    setNuevaIncidencia: PropTypes.func.isRequired,
    nuevaIncidencia: PropTypes.object.isRequired,
    setStepModal: PropTypes.func.isRequired
};

export default Step1;
