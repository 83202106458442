import './skeletonPedidoCard.scss';

const SkeletonPedidoCard = () => {
    return (
        <div className='pedido-card skeleton-pedido-card'>
            <div className='pedido-card__column left-align'>
                <span className='pedido-card__column--id skeleton-pedido-card__id pedido-card-loading-animation'></span>
                <span className='pedido-card__column--fecha skeleton-pedido-card__fecha pedido-card-loading-animation'></span>
            </div>
            <div className='pedido-card__column triple-column left-align'>
                <div className='triple-column-div'>
                    <span className='pedido-card__column--nombre skeleton-pedido-card__nombre pedido-card-loading-animation'></span>
                    <span className='pedido-card__column--modelo skeleton-pedido-card__modelo pedido-card-loading-animation'></span>
                </div>
            </div>
            <div className='pedido-card__column'>
                <span className='pedido-card__column--title skeleton-pedido-card__title pedido-card-loading-animation'></span>
                <span className='pedido-card__column--precio skeleton-pedido-card__precio pedido-card-loading-animation'></span>
            </div>
            <div className='pedido-card__column'>
                <span className='pedido-card__column--title skeleton-pedido-card__title pedido-card-loading-animation'></span>
                <span className='pedido-card__column--precio skeleton-pedido-card__precio pedido-card-loading-animation'></span>
            </div>
            <div className='pedido-card__column doble-column'>
                <span className='pedido-card__column--title skeleton-pedido-card__title pedido-card-loading-animation'></span>
                <span className='pedido-card__column--precio skeleton-pedido-card__precio pedido-card-loading-animation'></span>
            </div>
            <div className='pedido-card__acciones skeleton-pedido-card__acciones'>
                <div className='skeleton-pedido-card__acciones--accion'>
                    <span className='skeleton-pedido-card__acciones--accion--icon pedido-card-loading-animation'></span>
                    <span className='skeleton-pedido-card__acciones--accion--text1 pedido-card-loading-animation'></span>
                    <span className='skeleton-pedido-card__acciones--accion--text2 pedido-card-loading-animation'></span>
                </div>
                <div className='skeleton-pedido-card__acciones--accion'>
                    <span className='skeleton-pedido-card__acciones--accion--icon pedido-card-loading-animation'></span>
                    <span className='skeleton-pedido-card__acciones--accion--text3 pedido-card-loading-animation'></span>
                    <span className='skeleton-pedido-card__acciones--accion--text2 pedido-card-loading-animation'></span>
                </div>
                <div className='skeleton-pedido-card__acciones--accion'>
                    <span className='skeleton-pedido-card__acciones--accion--icon pedido-card-loading-animation'></span>
                    <span className='skeleton-pedido-card__acciones--accion--text2 pedido-card-loading-animation'></span>
                    <span className='skeleton-pedido-card__acciones--accion--text1 pedido-card-loading-animation'></span>
                </div>
            </div>
        </div>
    );
};

export default SkeletonPedidoCard;
