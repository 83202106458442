import axios from 'axios';
import config from '../config';
import { getCookie, setCookie } from './cookies';

const pageLoad = new Date().getTime();

const API = axios.create({
    baseURL: config.recomotor.apiUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

API.interceptors.request.use(
    (config) => {
        const token = getCookie('token_piezas_recomotor');
        if (token) {
            config.headers.Authorization = `bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

API.interceptors.response.use(async (response) => {
    await axios.head('/').then((res) => {
        const lastmod = res.headers['last-modified'];
        if (lastmod) {
            const lastModTime = new Date(lastmod).getTime();
            if (lastModTime > pageLoad) {
                return window.location.reload(true);
            }
        }
    });
    return response;
}, async function (error) {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
    // Logout user if token refresh didn't work or user is disabled
    if (error.response.data === 'Jwt expired or invalid') {
        setCookie('token_piezas_recomotor', '', -1);
        window.location.href = '/';

        return new Promise((resolve, reject) => {
            reject(error);
        });
    }

    return error.response;
});

export default API;
