import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './priceFilter.scss';
import { useState } from 'react';
import { MdCancel, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
const PriceFilter = ({ filterValues, setFilterValues }) => {
    const { t } = useTranslation();
    const [error, setError] = useState(null);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    const handleFilterPrice = (e) => {
        if ((e.target.value).includes('.')) {
            const decimalLength = (e.target.value).indexOf('.') + 1;
            if ((e.target.value).length - decimalLength > 2) {
                setError(null);
                return setFilterValues({ ...filterValues, [e.target.name]: (e.target.value).slice(0, -1) });
            }
        } else if (Number(e.target.value) > 9999.99) {
            return setError('maxError');
        } else if (Number(e.target.value) < 0) {
            return setError('minError');
        }
        setError(null);
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handleMaxPrice = (e) => {
        if ((Number(e.target.value) - Number(filterValues?.precioMin)) < 0) {
            setFilterValues({ ...filterValues, precioMax: '' });
            return setError('minHigher');
        }
        setError(null);
    };
    return (
        <div className='price-filter'>
            <span className='price-filter__btn'>
                <span className='price-filter__btn--nombre'>{t('catalogo-piezas.filtros-seccion.precio')}</span>
                <span className='price-filter__btn--borrar'>
                    <span
                        className='each-filter__btn--dropdown--text'
                        onClick={() =>
                            setFilterValues({ ...filterValues, precioMin: '', precioMax: '' })
                        }>
                        {t('catalogo-piezas.filtros-seccion.borrar')}
                    </span>
                    <span
                        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                        className='each-filter__btn--dropdown--arrow'
                    >
                        { isDropDownOpen
                            ? <MdKeyboardArrowUp />
                            : <MdKeyboardArrowDown />
                        }
                    </span>
                </span>
            </span>
            {isDropDownOpen && <div className='price-filter__inputs'>
                <div>
                    <input type='number' name='precioMin' value={filterValues?.precioMin} onChange={handleFilterPrice} /> <span>€</span>
                </div>
                <span>-</span>
                <div>
                    <input type='number' name='precioMax' value={filterValues?.precioMax} onChange={handleFilterPrice} onBlur={handleMaxPrice}/> <span>€</span>
                </div>
            </div>}
            {error && <span className='price-filter__error'><span><MdCancel/></span> {t(`errors.price.${error}`)}</span>}
        </div>
    );
};

PriceFilter.propTypes = {
    filterValues: PropTypes.object.isRequired,
    setFilterValues: PropTypes.func.isRequired
};

export default PriceFilter;
