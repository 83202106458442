import { types } from '../types';

export const saveFilters = (filters) => ({
    type: types.saveFilters,
    payload: filters
});

export const resetFilters = () => ({
    type: types.resetFilters
});
