import { useTranslation } from 'react-i18next';

import './totalPedido.scss';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const TotalPedido = ({ className = '' }) => {
    const { t } = useTranslation();
    const cart = useSelector(state => state.cart);

    const getSubTotal = () => {
        const subtotal = cart?.total + cart?.total_casco;
        return parseFloat(subtotal).toFixed(2);
    };
    const getIVA = () => {
        const IVAvalue = cart?.iva === 21 ? (getSubTotal() * 1.21) : 0;
        const IVA = IVAvalue === 0 ? 0 : IVAvalue - getSubTotal();
        return parseFloat(IVA).toFixed(2);
    };

    const getTotal = () => {
        return (Number(getSubTotal()) + Number(getIVA())).toFixed(2);
    };

    return <div className={`total-pedido ${className}`}>
        <h2>{t('carrito.pedido.title')}</h2>
        <p>
            <span>{t('carrito.pedido.subtotal')}</span>
            <span>{getSubTotal()}€</span>
        </p>
        <p className='total-pedido__impuestos'>
            <span>{t('carrito.pedido.impuestos')} ({cart?.iva}%)</span>
            <span>{getIVA()}€</span>
        </p>
        <h4>
            <span>{t('carrito.pedido.total')} *</span>
            <span>{getTotal()}€</span>
        </h4>
        <p className='total-pedido__transporte'>* {t('carrito.pedido.transporte')}</p>
    </div>;
};

TotalPedido.propTypes = {
    className: PropTypes.string
};

export default TotalPedido;
