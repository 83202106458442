module.exports = {
    app: {
        version: `${process.env.REACT_APP_VERSION || 'dev'}`,
        nodeEnv: process.env.NODE_ENV
    },
    recomotor: {
        apiUrl: `${process.env.REACT_APP_API_BASE}`,
        checkoutUrl: `${process.env.REACT_APP_CHECKOUT}/checkout/`
    },
    googlePlaces: {
        apiKey: process.env.REACT_APP_PLACES_API_KEY
    },
    recaptcha: {
        siteKey: process.env.REACT_APP_CAPTCHA_SITE_KEY
    },
    i18n: {
        debug: process.env.REACT_APP_I18_DEBUG || false,
        fallbackLng: 'es'
    },
    crypto: {
        secretKey: process.env.REACT_APP_CRYPTO_SECRET_KEY
    }
};
