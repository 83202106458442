import { types } from '../types';

export const filtersReducer = (state = null, action) => {
    switch (action.type) {
    case types.saveFilters:
        return action.payload;
    case types.resetFilters:
        return null;
    default:
        return state;
    }
};
