import { useTranslation } from 'react-i18next';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import './misDatos.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { apiRequest } from '../../../../services/api';
import { toast } from 'react-toastify';
import HandleAddressModal from '../../../shared/HandleAddressModal';
import { updateUser } from '../../../../redux/actions/auth';

const MisDatos = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.auth);
    const [userData, setUserData] = useState(null);
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getUserDataRequest = async () => {
        setIsLoading(true);
        const res = await apiRequest.user.getUserData();
        if (res?.success) {
            setUserData(res.userData);
            return setIsLoading(false);
        }
        return toast.error(t('errors.1'));
    };

    const handleUserData = async (newData, countryInfo) => {
        const res = await apiRequest.user.updateUserData({ ...newData, entity_id: userData?.entity_id });
        if (res?.success) {
            setIsOpenEditModal(false);
            setUserData({
                ...res.userData,
                pais_facturacion: countryInfo
            });
            dispatch(updateUser({
                nombre: res.userData.nombre,
                telefono: res.userData.telefono
            }));
            return toast.success(t('success.update.1'));
        }
        return toast.error(t('errors.1'));
    };

    useEffect(() => {
        getUserDataRequest();
    }, []);

    return <div className='mis-datos'>
        <span
            className='mis-datos__return'
            onClick={() => navigate('/area-personal')}>
            <BiArrowBack/>
        </span>
        <h2>{t('area-personal.datos.title')}</h2>
        <h3>{t('area-personal.datos.subtitle')}</h3>
        <section className='mis-datos__datos'>
            <div>
                <span>{t('area-personal.datos.nombre')}</span>
                {isLoading
                    ? <div className='skeleton-input'><span className='skeleton-input__span'></span></div>
                    : <span className='mis-datos__datos--info'>{userData?.nombre}</span>}
            </div>
            <div>
                <span>{t('area-personal.datos.cif')}</span>
                {isLoading
                    ? <div className='skeleton-input'><span className='skeleton-input__span'></span></div>
                    : <span className='mis-datos__datos--info'>{userData?.cif}</span>}
            </div>
            <div>
                <span>{t('area-personal.datos.tlf')}</span>
                {isLoading
                    ? <div className='skeleton-input'><span className='skeleton-input__span'></span></div>
                    : <span className='mis-datos__datos--info'>{userData?.telefono}</span>}
            </div>
            <div>
                <span>{t('area-personal.datos.email')}</span>
                {isLoading
                    ? <div className='skeleton-input'><span className='skeleton-input__span'></span></div>
                    : <span className='mis-datos__datos--info'>{userData?.email}</span>}
            </div>
            <div className='mis-datos__datos--full-width'>
                <span>{t('area-personal.datos.direccion')}</span>
                {isLoading
                    ? <div className='skeleton-input'><span className='skeleton-input__span'></span></div>
                    : <span className='mis-datos__datos--info'>{userData?.direccion_facturacion}</span>}
            </div>
            <div>
                <span>{t('area-personal.datos.pais')}</span>
                {isLoading
                    ? <div className='skeleton-input'><span className='skeleton-input__span'></span></div>
                    : <span className='mis-datos__datos--info'>{userData?.pais_facturacion?.nombre}</span>}
            </div>
            <div>
                <span>{t('area-personal.datos.poblacion')}</span>
                {isLoading
                    ? <div className='skeleton-input'><span className='skeleton-input__span'></span></div>
                    : <span className='mis-datos__datos--info'>{userData?.codigo_postal_facturacion} {user?.poblacion_facturacion}{user?.area_facturacion && ', '}{user?.area_facturacion}</span>}
            </div>
            <div className='mis-datos__datos--full-width btn-container'>
                <button onClick={() => setIsOpenEditModal(true)}>{t('area-personal.datos.edit')}</button>
            </div>
        </section>
        {isOpenEditModal && <HandleAddressModal setIsOpenModal={setIsOpenEditModal} addressData={userData} handleAddressData={handleUserData} isDirFact={true} />}
    </div>;
};

export default MisDatos;
