import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { MdLocalShipping, MdSdCardAlert, MdSimCardDownload } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { TbProgressAlert } from 'react-icons/tb';
import Spinner from '../../../../../shared/Spinner';
import PropTypes from 'prop-types';
import '../pedidoCard.scss';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 210,
        color: '#222222',
        boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                )
            }
        }
    }
}));
const ActionsMenu = ({ pedido, setIsSeguimientoOpen, setInfoToShow, isLimitIncidents, startNuevaIncidencia, partsWithIncidents, setIsVerIncidenciaOpen, handleDownloadInvoices, isLoading }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='actions-menu'>
            <IconButton
                id="actions-menu-button"
                aria-controls={open ? 'actions-menu-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <StyledMenu
                id="actions-menu-menu"
                MenuListProps={{
                    'aria-labelledby': 'actions-menu-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    setIsSeguimientoOpen(true);
                    setInfoToShow(pedido);
                    handleClose();
                }} disableRipple>
                    <MdLocalShipping />{t('area-personal.pedidos.seguimiento')}
                </MenuItem>
                {!isLimitIncidents && <MenuItem
                    onClick={() => {
                        startNuevaIncidencia(pedido);
                        handleClose();
                    }}>
                    <MdSdCardAlert />
                    {t('area-personal.pedidos.incidencia')}
                </MenuItem>}
                {partsWithIncidents?.length > 0 && <MenuItem
                    onClick={() => {
                        setIsVerIncidenciaOpen(true);
                        setInfoToShow(partsWithIncidents);
                        handleClose();
                    }}>
                    <TbProgressAlert />
                    {t('area-personal.incidencias.ver')}
                </MenuItem>}
                <MenuItem onClick={handleDownloadInvoices}>
                    {isLoading ? <p><Spinner className='spinner-descargar-factura'/></p> : <MdSimCardDownload />}
                    {pedido.facturas.length > 1 ? t('area-personal.pedidos.descargar-more') : t('area-personal.pedidos.descargar')}
                </MenuItem>
            </StyledMenu>
        </div>
    );
};

ActionsMenu.propTypes = {
    pedido: PropTypes.object.isRequired,
    setIsSeguimientoOpen: PropTypes.func,
    setIsDetallesPedidoOpen: PropTypes.func,
    setInfoToShow: PropTypes.func,
    startNuevaIncidencia: PropTypes.func,
    setIsVerIncidenciaOpen: PropTypes.func,
    isLimitIncidents: PropTypes.bool,
    partsWithIncidents: PropTypes.array,
    handleDownloadInvoices: PropTypes.func,
    isLoading: PropTypes.bool
};

export default ActionsMenu;
