import { useTranslation } from 'react-i18next';
import PedidoCard from '../PedidoCard';

import './pedidosContainerMob.scss';
import PropTypes from 'prop-types';
import SkeletonPedidoCard from '../SkeletonPedidoCard';

const PedidosContainerMob = ({ pedidosData, observerTarget, setIsSeguimientoOpen, setIsDetallesPedidoOpen, setInfoToShow, startNuevaIncidencia, setIsVerIncidenciaOpen, isLoading }) => {
    const { t } = useTranslation();
    return <section className='pedidos-container-mob'>
        <p className='pedidos-container-mob__header'>
            <span>{t('area-personal.pedidos.pedido')}</span>
            <span>{t('area-personal.pedidos.pieza')}</span>
        </p>
        {pedidosData?.map(pedido =>
            <PedidoCard
                setIsSeguimientoOpen={setIsSeguimientoOpen}
                setIsDetallesPedidoOpen={setIsDetallesPedidoOpen}
                setInfoToShow={setInfoToShow}
                startNuevaIncidencia={startNuevaIncidencia}
                setIsVerIncidenciaOpen={setIsVerIncidenciaOpen}
                key={pedido?.id}
                pedido={pedido}/>)}
        {!isLoading && pedidosData?.length < 1 && <div className='pedidos-container-mob__nodata'>{t('area-personal.pedidos.nodata')}</div>}
        {isLoading && <><SkeletonPedidoCard/><SkeletonPedidoCard/><SkeletonPedidoCard/><SkeletonPedidoCard/></>}
        <div ref={observerTarget}></div>
    </section>;
};

PedidosContainerMob.propTypes = {
    pedidosData: PropTypes.array.isRequired,
    observerTarget: PropTypes.any.isRequired,
    setIsSeguimientoOpen: PropTypes.func.isRequired,
    setIsDetallesPedidoOpen: PropTypes.func.isRequired,
    setInfoToShow: PropTypes.func.isRequired,
    startNuevaIncidencia: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setIsVerIncidenciaOpen: PropTypes.func.isRequired
};

export default PedidosContainerMob;
