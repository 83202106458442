import { useTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';

const FormPasswordInput = ({
    className = '',
    name,
    isForgotPassword = false,
    onClick,
    label,
    onChange
}) => {
    const { t } = useTranslation();
    const { register, formState: { errors }, watch, setValue } = useFormContext();
    const [isShowPassword, setIsShowPassword] = useState(false);

    return (
        <div>
            <label>
                <span className={`${className}--labeltxt`}>
                    <span>{label}</span>
                    {isForgotPassword && <span
                        className={`${className}--labeltxt--olvidado`}
                        onClick={onClick}>
                        {t('login.form.olvidado')}
                    </span>}
                </span>
                <input
                    type={isShowPassword ? 'text' : 'password'}
                    {...register(name, {
                        required: 'required',
                        pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/,
                            message: 'formato'
                        }
                    })}
                    onChange={(e) => {
                        setValue(name, e.target.value);
                        onChange && onChange(e);
                    }}
                />
                <span className={`${className}--eye`}>
                    {watch(name, '') &&
                        (isShowPassword
                            ? <FaEyeSlash
                                onClick={() =>
                                    setIsShowPassword(false)
                                }
                            />
                            : <FaEye
                                onClick={() =>
                                    setIsShowPassword(true)
                                }
                            />
                        )}
                </span>
            </label>
            {errors[name] && (
                <span
                    role="alert"
                    className={`${className}--error`}
                >
                    <MdCancel />{' '}
                    {t(`input-form.errors.${name}.${errors[name].message}`)}
                </span>
            )}
        </div>
    );
};

FormPasswordInput.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    isForgotPassword: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string,
    onChange: PropTypes.func
};

export default FormPasswordInput;
