import PropTypes from 'prop-types';
import ModalContainer from '../ModalContainer';
import FormAddress from '../FormAddress';

const HandleAddressModal = ({ setIsOpenModal, addressData, handleAddressData, isDirFact }) => {
    return (
        <ModalContainer setIsOpenModal={setIsOpenModal} className='modal-form-address'>
            <FormAddress address={addressData} uploadDirection={handleAddressData} isDirFact={isDirFact} />
        </ModalContainer>
    );
};

HandleAddressModal.propTypes = {
    setIsOpenModal: PropTypes.func,
    addressData: PropTypes.any,
    handleAddressData: PropTypes.func,
    isDirFact: PropTypes.bool
};

export default HandleAddressModal;
