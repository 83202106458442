import { useTranslation } from 'react-i18next';
import './detalleCarrito.scss';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import SkeletonDetalleCarrito from './SkeletonDetalleCarrito';
import EachCardDetalle from './EachCardDetalle';
import { useSelector } from 'react-redux';
import emptyCart from '../../../images/cart/empty-cart.png';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../../../services/api';

const DetalleCarrito = ({ className = '', piezasStatusChecked, setPiezasStatusChecked }) => {
    const { t } = useTranslation();
    const cart = useSelector(state => state.cart);
    const navigate = useNavigate();
    const [isStockLoading, setIsStockLoading] = useState(false);
    const [isCartFromLocalStorage, setIsCartFromLocalStorage] = useState(true);

    const checkStokStatus = async () => {
        setIsStockLoading(true);
        const newArr = [];
        for (const pieza of cart?.piezas) {
            const res = await apiRequest.stock.getStockStatus(pieza?.pieza_integracion_id);
            if (res?.success) {
                newArr.push({ ...pieza, ...res });
            } else toast.error(t('errors.1'));
        }
        setPiezasStatusChecked(newArr);
        return setIsStockLoading(false);
    };

    useEffect(() => {
        if (cart?.piezas?.length > 0) {
            if (piezasStatusChecked?.length > 0) setPiezasStatusChecked([]);
            checkStokStatus();
        }

        const savedCart = JSON.parse(localStorage.getItem('cart'));
        if (savedCart) return setIsCartFromLocalStorage(true);
        setIsCartFromLocalStorage(false);
    }, [cart?.validado, cart?.piezas]);

    useEffect(() => {
        if (piezasStatusChecked?.length > 0) {
            const newPiezasStatusChecked = cart.piezas.map((part) => {
                const partState = piezasStatusChecked.find(item => item.pieza_integracion_id === part.pieza_integracion_id);
                return { ...part, estado: partState.estado };
            });
            setPiezasStatusChecked(newPiezasStatusChecked);
        }
    }, [cart]);

    return (
        <div className="detalle-carrito">
            {cart?.piezas?.length > 0 && <div className={`detalle-carrito__header ${className}`}>
                <span className="detalle-carrito__header--producto">
                    {t('carrito.detalle.producto')}
                </span>
                <span className="detalle-carrito__header--precio">
                    {t('carrito.detalle.precio')}
                </span>
            </div>}
            {!isStockLoading && piezasStatusChecked?.map((pieza) => (
                <EachCardDetalle
                    key={pieza?.pieza_integracion_id}
                    pieza={pieza}
                    isCasco={pieza?.casco && pieza?.casco !== 0}
                    className={className}
                />))
            }
            { cart?.piezas?.length === 0 &&
                <div className={`detalle-carrito__no-data ${isCartFromLocalStorage ? 'transparent-background' : ''}`}>
                    {!isCartFromLocalStorage && <>
                        <img src={emptyCart} alt='cart' />
                        <h2 className='detalle-carrito__no-data--empty'>{t('carrito.detalle.no-data')}</h2>
                        <button className='detalle-carrito__no-data--btn' onClick={() => navigate('/') }>{t('carrito.detalle.return')}</button>
                    </>}
                </div>}
            {
                isStockLoading && cart?.piezas?.map(pieza => <SkeletonDetalleCarrito key={pieza?.pieza_integracion_id}/>)
            }
        </div>
    );
};

DetalleCarrito.propTypes = {
    className: PropTypes.string,
    piezasStatusChecked: PropTypes.array.isRequired,
    setPiezasStatusChecked: PropTypes.any.isRequired
};

export default DetalleCarrito;
