import { types } from '../types';

export const cartReducer = (state = { piezas: [] }, action) => {
    switch (action.type) {
    case types.updateCart:
        return action.payload;
    case types.addToCart:
        return {
            ...state,
            piezas: [...state.piezas, action.payload]
        };
    case types.removeToCart:
        return state.filter(part => part.id !== action.payload);
    default:
        return state;
    }
};
