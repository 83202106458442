import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId
} from 'react-places-autocomplete';
import { toast } from 'react-toastify';
import './autocomplete.scss';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md';

const GooglePlacesAutocomplete = ({ defaultValue, country, disabled, onSelectResult }) => {
    const [selected, setSelected] = useState('');

    const { t } = useTranslation();
    const { watch, setError, clearErrors, formState: { errors } } = useFormContext();

    useEffect(() => {
        if (defaultValue) {
            setSelected(defaultValue);
        }
    }, [country, defaultValue, disabled]);

    const handleSelectPlace = (place) => {
        setSelected(place);
    };

    const handleSelect = async (address, placeId) => {
        if (errors.direccion) clearErrors('direccion');
        await geocodeByAddress(address);
        const [place] = await geocodeByPlaceId(placeId);
        const { long_name: extra = '' } = place.address_components.find(c => c.types.includes('subpremise')) || {};
        const { long_name: num = null } = place.address_components.find(c => c.types.includes('street_number')) || {};
        const { long_name: street = null } = place.address_components.find(c => c.types.includes('route')) || {};
        const { long_name: postalCode = null } = place.address_components.find(c => c.types.includes('postal_code')) || {};
        const { long_name: city = null } = place.address_components.find(c => c.types.includes('locality')) || {};
        const { long_name: adminArea = null } = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
        const { long_name: adminArea2 = null } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};

        if (!street || !postalCode || !city || !adminArea2 || !num) {
            if (!num && !postalCode) {
                toast.warning('El número de calle es obligatorio para esta dirección');
                return;
            } else {
                if (!street || !postalCode || !adminArea2) {
                    toast.warning('Faltan datos en la dirección');
                    return;
                }
            }
        }

        const selectedAddress = street + (num ? `, ${num}` : '') + (extra ? ` ${extra}` : '');
        setSelected(selectedAddress);

        onSelectResult({
            direccion: place.types.find(t => t === 'establishment') ? `${street}, ${num} ${extra}` : selectedAddress,
            codigo_postal: postalCode,
            poblacion: city,
            area: `${adminArea2}, ${adminArea}`
        });
    };
    const handleOnBlur = () => {
        if (!watch('codigo_postal')) {
            setSelected('');
            return setError('direccion', 'select-option');
        }
        clearErrors('direccion');
    };

    return (
        <PlacesAutocomplete
            value={selected}
            searchOptions={{
                types: ['address'],
                language: 'es',
                componentRestrictions: {
                    country: [country]
                }
            }}
            onChange={handleSelectPlace}
            onSelect={handleSelect}
            onError={(status, clearSuggestions) => {
                if (status === 'ZERO_RESULTS') {
                    console.log('No hay resultados');
                    toast.warning('No hay resultados');
                } else {
                    console.log(
                        'Google Maps API returned error with status: ',
                        status
                    );
                    toast.error(
                        'Google Maps API returned error with status: ',
                        status
                    );
                }

                clearSuggestions();
            }}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
            }) => (
                <div className="places-search">
                    <label className="form-dir-envio__label">
                        {t('input-form.name.direccion')}
                        <input
                            {...getInputProps({
                                className: 'form-control',
                                disabled
                            })}
                            required
                            autoComplete="new-password"
                            onBlur={handleOnBlur}
                        />
                    </label>
                    <div className="places-search-container">
                        {loading && (
                            <div className="suggestion-item">Loading...</div>
                        )}
                        {suggestions.map((suggestion, i) => {
                            const className = suggestion.active
                                ? 'suggestion-item-active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer'
                                }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer'
                                };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                    })}
                                    key={'place-suggestion-' + i}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                    {errors.direccion && (
                        <span role="alert">
                            <MdCancel />{' '}
                            {t('input-form.errors.direccion.selected')}
                        </span>
                    )}
                </div>
            )}
        </PlacesAutocomplete>
    );
};

GooglePlacesAutocomplete.propTypes = {
    defaultValue: PropTypes.string,
    country: PropTypes.string,
    disabled: PropTypes.bool,
    onSelectResult: PropTypes.func
};
export default GooglePlacesAutocomplete;
