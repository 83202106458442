import './skeletonDetalleCarrito.scss';
const SkeletonDetalleCarrito = () => {
    return (
        <div className='detalle-carrito__pieza skeleton-detalle-carrito'>
            <div className='detalle-carrito__pieza--foto skeleton-detalle-carrito__foto'>
                <span className='skeleton-detalle-carrito__foto--img detalle-carrito-loading-animation'></span>
            </div>
            <div className='detalle-carrito__pieza--info'>
                <h4 className='skeleton-detalle-carrito__title detalle-carrito-loading-animation'></h4>
                <p className='detalle-carrito__pieza--info--subtitle skeleton-detalle-carrito__subtitle detalle-carrito-loading-animation'></p>
                <p className='detalle-carrito__pieza--info--labels skeleton-detalle-carrito__labels'>
                    <span className='skeleton-detalle-carrito__labels--label1 detalle-carrito-loading-animation'></span>
                    <span className='skeleton-detalle-carrito__labels--label1 detalle-carrito-loading-animation'></span>
                </p>
                <p className='detalle-carrito__pieza--info--observaciones skeleton-detalle-carrito__labels--observaciones detalle-carrito-loading-animation'></p>
            </div>
            <h3 className='detalle-carrito__pieza--precio skeleton-detalle-carrito__precio detalle-carrito-loading-animation'></h3>
            <span className='detalle-carrito__pieza--eliminar skeleton-detalle-carrito__eliminar detalle-carrito-loading-animation'></span>
        </div>
    );
};

export default SkeletonDetalleCarrito;
