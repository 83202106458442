import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import PropTypes from 'prop-types';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { CgClose } from 'react-icons/cg';
import Image from '../../../../shared/Image';

import './modalZoomImage.scss';

const ModalZoomImage = ({ startIndex, fotos, setIsZoomOpen }) => {
    return (
        <div className='modal-zoom-image'>
            <div className='modal-zoom-image__container'>
                <div className="modal-zoom-image__container--header">
                    <div className="toolbar">
                        <span>
                            <CgClose onClick={() => setIsZoomOpen(false)}/>
                        </span>
                    </div>
                </div>
                <Swiper
                    style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff'
                    }}
                    loop={true}
                    navigation={true}
                    zoom={true}
                    pagination={{
                        clickable: true
                    }}
                    modules={[Zoom, Navigation, Pagination]}
                    initialSlide={startIndex}
                    className="swiperPrincipal"
                >
                    { fotos?.map((image, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <div className="swiper-zoom-container">
                                    <Image foto={image} />
                                </div>
                            </SwiperSlide>
                        );
                    }) }
                </Swiper>
            </div>
        </div>
    );
};

ModalZoomImage.propTypes = {
    startIndex: PropTypes.number,
    fotos: PropTypes.array,
    setIsZoomOpen: PropTypes.func
};

export default ModalZoomImage;
