import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { MdShoppingCart } from 'react-icons/md';
import PropTypes from 'prop-types';
import { apiRequest } from '../../../services/api';
import { toast } from 'react-toastify';
import { addToCart } from '../../../redux/actions/cart';
import { removeToFavorites } from '../../../redux/actions/favorites';
import { encryptPartId } from '../../../utils';

const AddToCartBtn = ({ isLoading, setIsLoading, pieza, classNameBtn = '', classNameDisabled = '', classNameSpinner = '' }) => {
    const { t } = useTranslation();
    const cart = useSelector(state => state.cart);
    const favoritosArr = useSelector(state => state.favorites);
    const dispatch = useDispatch();

    const handleAddToCart = async (e) => {
        e.stopPropagation();
        if (isLoading) return;
        if (pieza.estado !== 1) return;
        const isAlreadyAdded = cart?.piezas?.find(el => el?.pieza_integracion_id === pieza?.id);
        if (!isAlreadyAdded) {
            setIsLoading(true);
            const piezaData = {
                pieza_integracion_id: pieza?.id,
                id_referencia_plataforma: pieza?.id_original,
                nombre: pieza?.nombre,
                tipo_pieza_id: pieza?.tipo_pieza_id,
                referencia_pieza: pieza?.referencia,
                precio_original: pieza?.precio_original,
                precio_compra: pieza?.precio_compra,
                precio_venta: pieza?.precio_venta,
                precio_transporte: 0,
                proveedor_id: pieza?.proveedor_id,
                casco: false,
                marca: pieza?.marca,
                modelo: pieza?.modelo,
                integracion_json: JSON.stringify(pieza),
                tipo_garantia_id: pieza?.tipo_garantia_id,
                imgs: pieza?.imgs
            };
            dispatch(addToCart(piezaData));
            addPartToLocalStorage(pieza?.id);

            const isAlreadyAddedToFavoritos = favoritosArr?.find(el => el?.id === pieza?.id);
            if (isAlreadyAddedToFavoritos) deleteToFavorites();
            toast.success(t('success.cart.1'));
            return setIsLoading(false);
        }
    };

    const addPartToLocalStorage = (partId) => {
        const currentCart = JSON.parse(localStorage.getItem('cart')) || [];
        const newPart = encryptPartId(partId);
        localStorage.setItem('cart', JSON.stringify([...currentCart, newPart]));
    };

    const deleteToFavorites = async () => {
        const res = await apiRequest.user.deleteToFavoritesRequest({ piezaStockId: pieza?.id });
        if (res?.success) {
            return dispatch(removeToFavorites(pieza?.id));
        }
        return toast.error(t('errors.favoritos.2'));
    };

    return (
        <p className={`${classNameBtn} ${cart?.piezas?.find(el => el?.pieza_integracion_id === pieza?.id) || isLoading ? classNameDisabled : ''}`} onClick={handleAddToCart}>
            <span className={`${classNameBtn}--icon`}>
                {isLoading ? <Spinner className={classNameSpinner}/> : <MdShoppingCart />}
            </span>
            <span className={`${classNameBtn}--text`}>{t('catalogo-piezas.pieza-card.añadir')}</span>
        </p>
    );
};

AddToCartBtn.propTypes = {
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
    pieza: PropTypes.object.isRequired,
    classNameBtn: PropTypes.string,
    classNameSpinner: PropTypes.string,
    classNameDisabled: PropTypes.string
};

export default AddToCartBtn;
