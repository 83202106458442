import { useState } from 'react';

export const usePhoneChange = ({ clearErrors }) => {
    const [phone, setPhone] = useState('');

    const handlePhoneChange = (event) => {
        let inputValue = event.target.value;
        if (!/^[0-9()+-]*$/.test(inputValue)) {
            inputValue = inputValue.slice(0, -1);
        }
        setPhone(inputValue);
        if (inputValue.length >= 9) clearErrors('telefono');
    };

    return { phone, handlePhoneChange, setPhone };
};
