import PropTypes from 'prop-types';

import './modalDetallesPedido.scss';
import { useTranslation } from 'react-i18next';
import useScreenWidth from '../../../../../hooks/useScreenWidth';
import { useEffect, useState } from 'react';
import ModalDetalleEachCard from './ModalDetalleEachCard';
import { apiRequest } from '../../../../../services/api';
import SkeletonDetalleEachCard from './SkeletonDetalleEachCard';
import { toast } from 'react-toastify';
import ModalContainer from '../../../../shared/ModalContainer';

const ModalDetallesPedido = ({ setIsDetallesPedidoOpen, pedido }) => {
    const [detallesPedido, setDetallesPedido] = useState(null);
    const { t } = useTranslation();
    const screenWidth = useScreenWidth();
    const reqGetOrderDetails = async (numPedido) => {
        const res = await apiRequest.user.getOrderDetails({ numPedido });
        if (res?.success) {
            return setDetallesPedido(res?.piezas);
        }
        return toast.error(t('errors.1'));
    };

    useEffect(() => {
        reqGetOrderDetails(pedido?.id);
        window.scrollTo(0, 0);
    }, []);

    return (
        <ModalContainer setIsOpenModal={setIsDetallesPedidoOpen} className='modal-detalles'>
            <h2>{t('area-personal.pedidos.modals.detalles.title')}</h2>
            <p className='modal-detalles__titles'>
                <span>{t('area-personal.pedidos.pedido')}</span>
                <span>{t('area-personal.pedidos.precio')}</span>
                <span>{t('area-personal.pedidos.envio')}</span>
                <span>{t('area-personal.pedidos.total')}</span>
            </p>
            <div className='modal-detalles__pedido'>
                <p className='modal-detalles__pedido--codigo'>
                    <span>{pedido?.id}</span>
                    <span>{pedido?.fecha}</span>
                </p>
                <p>{pedido?.total}€</p>
                <p>{pedido?.total_envio}€</p>
                <p>{pedido?.total_iva}€</p>
            </div>
            <section className='modal-detalles__piezas'>
                <p>
                    <span>{t('area-personal.pedidos.modals.detalles.piezasPedido')}</span>
                    {screenWidth >= 992 && <>
                        <span>{t('area-personal.pedidos.precio')}</span>
                        <span>{t('area-personal.pedidos.envio')}</span>
                    </>}
                </p>
                {detallesPedido
                    ? detallesPedido?.map(pieza => <ModalDetalleEachCard key={pieza?.id} pieza={pieza} iva={pedido?.iva}/>)
                    : <SkeletonDetalleEachCard/>}
            </section>
        </ModalContainer>);
};

ModalDetallesPedido.propTypes = {
    setIsDetallesPedidoOpen: PropTypes.func,
    pedido: PropTypes.object
};

export default ModalDetallesPedido;
