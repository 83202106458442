import './skeletonTipoIncidencia.scss';
const SkeletonTipoIncidencia = () => {
    return (
        <div className='modal-nueva-incidencia__incidencias--each-container skeleton-tipo-incidencia'>
            <h4 className='skeleton-tipo-incidencia__title tipo-incidencia-loading-animation'></h4>
            <p className='skeleton-tipo-incidencia__text1 tipo-incidencia-loading-animation'></p>
            <p className='skeleton-tipo-incidencia__text2 tipo-incidencia-loading-animation'></p>
            <p className='skeleton-tipo-incidencia__text3 tipo-incidencia-loading-animation'></p>
        </div>
    );
};

export default SkeletonTipoIncidencia;
