import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
const InfoPieza = ({ pieza, className = 'info-detalle__container--more-info' }) => {
    const { t } = useTranslation();
    return <>
        <div>
            <p className={`${className} to-uppercase`}>{t('catalogo-piezas.pieza-card.version')} <span>{pieza?.nombre}</span></p>
            <p className={`${className} to-uppercase`}>{t('catalogo-piezas.pieza-card.motor')} <span>{pieza?.motor}</span></p>
        </div>
        <div>
            <p className={className}>{t('catalogo-piezas.pieza-card.año')} <span>{pieza?.anopieza === '0' ? '-' : pieza?.anopieza}</span></p>
        </div>
    </>;
};

InfoPieza.propTypes = {
    pieza: PropTypes.object,
    className: PropTypes.string
};

export default InfoPieza;
