import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './infoDetalle.scss';
import { useState } from 'react';
import InfoPieza from '../../../shared/InfoPieza';
import { useSelector } from 'react-redux';
import AddToCartBtn from '../../../shared/AddToCartBtn';
import AddToFavoritosBtn from '../../../shared/AddToFavoritosBtn';
import NoDataBtns from '../../../shared/NoDataBtns';

const InfoDetalle = ({ pieza }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const cart = useSelector(state => state.cart);
    const user = useSelector(state => state.auth);

    return <div className='info-detalle'>
        <div className='info-detalle__container'>
            <p className='info-detalle__container--disponible'><span>{t('catalogo-piezas.pieza-card.disponible')}</span> <span><b>{pieza?.disponible || 'exprés'}</b></span></p>
            <>
                <h3 className='info-detalle__container--nombre'>{pieza?.nombre}</h3>
                <h4 className='info-detalle__container--modelo'>{pieza?.marca} {pieza?.modelo}</h4>
                <p className='info-detalle__container--labels'>
                    <span>OEM: <b>{pieza?.referencia}</b></span>
                    <span>ID: <b>{pieza?.id}</b></span>
                </p>
                <p className='info-detalle__container--observaciones'>{t('catalogo-piezas.pieza-card.observaciones')} {pieza?.notapublica || '-'}</p>
                <InfoPieza pieza={pieza} className='info-detalle__container--more-info' />
            </>
            <div className='info-detalle__container--add-cart'>
                <h3>{pieza?.precio_venta !== 0 ? (pieza?.precio_venta).toFixed(2) + '€ ' : t('catalogo-piezas.pieza-card.consultar')}
                    <span>{pieza?.precio_venta !== 0
                        ? (user?.tipo_cliente === 2 ? '' : t('catalogo-piezas.pieza-card.iva'))
                        : t('catalogo-piezas.pieza-card.consultar-text')}</span></h3>
                {pieza?.precio_venta !== 0
                    ? <AddToCartBtn
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        pieza={pieza}
                        classNameSpinner='spinner-pieza-detalle'
                        classNameDisabled='info-detalle__container--add-cart--disabled-to-add'
                    />
                    : <NoDataBtns
                        className='info-detalle__container--no-data-btns'
                        pieza={pieza}
                        isNotPrice={true}
                    />}
            </div>
            {cart && cart?.piezas?.find(el => el?.pieza_integracion_id === pieza?.id) && <p className='pieza-card__en-carrito info-detalle__en-carrito'>{t('catalogo-piezas.pieza-card.en-carrito')}</p>}
            <AddToFavoritosBtn
                pieza={pieza}
                classNameDisabled='info-detalle__container--favorites--disabled'
                classNameBtn='info-detalle__container--favorites' />
        </div>
    </div>;
};

InfoDetalle.propTypes = {
    pieza: PropTypes.object
};

export default InfoDetalle;
