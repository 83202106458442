import { IoMdAlert } from 'react-icons/io';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './dropDownCard.scss';

const DropDownCard = ({ envio }) => {
    const { t } = useTranslation();

    return <div className='drop-down-card'>
        {
            envio?.transportista?.id === 1 && (
                <div className='drop-down-card__alert'>
                    <span className='drop-down-card__alert--icon'><IoMdAlert/></span>
                    <p>
                        {t('area-personal.pedidos.modals.seguimiento.more-info.message.1')}{' '}
                        <span className='drop-down-card__alert--tlf'>+34 644 175 425</span>{' '}
                        {t('area-personal.pedidos.modals.seguimiento.more-info.message.2')}{' '}
                        <span className='drop-down-card__alert--num-envio'>{envio?.codigo_transporte}</span>
                    </p>
                </div>
            )
        }
        <h4>{t('area-personal.pedidos.modals.seguimiento.more-info.estados')}</h4>
        <div className='drop-down-card__main-container'>
            <div className='drop-down-card__estado-container'>
                <p>
                    <span>{t('area-personal.pedidos.modals.seguimiento.more-info.dia')}</span>
                    <span>{t('area-personal.pedidos.modals.seguimiento.estado')}</span>
                </p>
                { envio?.estado?.nombre
                    ? <div>
                        <span>{envio?.fecha}</span>
                        <span>
                            {(envio?.estado?.nombre).toUpperCase()}
                        </span>
                    </div>
                    : <>{
                        envio?.estado?.map((estado, idx) =>
                            <div key={idx}>
                                <span>{(estado?.nombre).toUpperCase() === 'DESCONOCIDO' ? envio?.fecha : `${estado?.fecha}${estado?.hora ? `- ${estado?.hora}` : ''}`}</span>
                                <span
                                    className={(estado?.nombre).toUpperCase() === 'ENTREGADO' ? 'drop-down-card__estado-container--primary-color' : ''}>
                                    {(estado?.nombre).toUpperCase()}
                                </span>
                            </div>)}</>
                }
            </div>
            <div className='drop-down-card__medidas-container'>
                <p>
                    <span>{t('area-personal.pedidos.modals.seguimiento.more-info.peso')}</span>
                    <span>{envio?.peso} Kg</span>
                </p>
                <p>
                    <span>{t('area-personal.pedidos.modals.seguimiento.more-info.alto')}</span>
                    <span>{envio?.alto} cm</span>
                </p>
                <p>
                    <span>{t('area-personal.pedidos.modals.seguimiento.more-info.largo')}</span>
                    <span>{envio?.largo} cm</span>
                </p>
                <p>
                    <span>{t('area-personal.pedidos.modals.seguimiento.more-info.ancho')}</span>
                    <span>{envio?.ancho} cm</span>
                </p>
            </div>
        </div>
    </div>;
};

DropDownCard.propTypes = {
    envio: PropTypes.object.isRequired
};

export default DropDownCard;
