import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { apiRequest } from '../../../../services/api';
import { setCookie } from '../../../../services/cookies';
import { useDispatch } from 'react-redux';
import { login } from '../../../../redux/actions/auth';
import { updateFavorites } from '../../../../redux/actions/favorites';
import { useRef, useState } from 'react';
import Spinner from '../../Spinner';
import { OkMessage } from '../../Alert';
import { handleErrorMessage } from '../../../../utils';
import FormInput from '../../FormInput';
import FormPasswordInput from '../../FormPasswordInput';

const FormLogin = ({ setIsOpenAuthModal, isGuestModal = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const emailRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOkMessageOpen, setIsOkMessageOpen] = useState(false);

    const methods = useForm();
    const {
        handleSubmit,
        clearErrors,
        getValues,
        setError,
        setValue
    } = methods;

    const onSubmit = async (data) => {
        setIsLoading(true);
        const res = await apiRequest.auth.login(data);
        if (res?.success) {
            setCookie('token_piezas_recomotor', res?.token, 1);
            dispatch(login(res?.user));
            dispatch(updateFavorites(res?.favorites));
            return setIsOpenAuthModal(false);
        }
        const errorMessage = handleErrorMessage(res?.error);
        toast.error(errorMessage);

        return setIsLoading(false);
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        const email = getValues('email');
        const isCorrectEmail = validateEmail(email);
        if (!isCorrectEmail) {
            return setError('email', {
                type: 'required',
                message: 'required'
            });
        }
        const res = await apiRequest.auth.resetPassword(email);
        if (res?.success) {
            return setIsOkMessageOpen(true);
        }
        return toast.error(t('errors.1'));
    };

    const validateEmail = (email) => {
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };

    const handleAutocomplete = () => {
        setValue('email', emailRef.current.value);
    };

    return (
        <div className={`form-login ${isGuestModal ? 'form-login-guest' : ''}`}>
            <div className={`form-login__container ${isGuestModal ? 'form-login-guest' : ''}`}>
                <h3 className="form-login__container--title">
                    {t('login.title')}
                </h3>
                <FormProvider {...methods} >
                    <form
                        className="form-login__container--form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormInput
                            name='email'
                            type='email'
                            pattern={{
                                value: /\S+@\S+\.\S+/,
                                message: 'formato'
                            }}
                            inputRef={emailRef}
                            // value={getValues('email')}
                            onChange={() => clearErrors('email')}
                            classNameError="form-login__container--form--error"
                        />
                        <FormPasswordInput
                            name='password'
                            label={t('login.form.contraseña')}
                            onChange={() => clearErrors('email')}
                            className="form-login__container--form"
                            isForgotPassword={true}
                            onClick={(e) => {
                                handleAutocomplete();
                                handleResetPassword(e);
                            }}
                        />
                        <button onClick={handleAutocomplete} type="submit">
                            {isLoading
                                ? <Spinner className="spinner-login" />
                                : t('login.form.btn')
                            }
                        </button>
                    </form>
                </FormProvider>
                {!isGuestModal && <p className="form-login__container--nocuenta">
                    <span>{t('login.form.no-cuenta')}</span>
                    <span
                        className="form-login__container--nocuenta--link"
                        onClick={() => setIsOpenAuthModal('register')}
                    >
                        {t('login.form.alta')}
                    </span>
                </p>}
            </div>
            {isOkMessageOpen && (
                <OkMessage
                    setIsOkMessageOpen={setIsOkMessageOpen}
                    text={t('login.form.reset-password')}
                />
            )}
        </div>
    );
};

FormLogin.propTypes = {
    setIsOpenAuthModal: PropTypes.func,
    isGuestModal: PropTypes.bool
};

export default FormLogin;
