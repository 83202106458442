import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './modalNuevaIncidencia.scss';
import { toast } from 'react-toastify';
import { apiRequest } from '../../../../../services/api';
// import ChoosePiezaCard from './ChoosePiezaCard'
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import ModalContainer from '../../../../shared/ModalContainer';

const ModalNuevaIncidencia = ({ setIsNuevaIncidenciaOpen, setIsOkMessageOpen, pedido, pedidosData, setPedidosData }) => {
    const [tiposIncidencia, setTiposIncidencia] = useState(null);
    const [isConcidionesChecked, setIsConcidionesChecked] = useState(false);
    const [stepModal, setStepModal] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [nuevaIncidencia, setNuevaIncidencia] = useState({
        tipo: '',
        comentario: '',
        fotos: '',
        piezaId: '',
        proveedor_id: ''
    });
    const [nameFotos, setNameFotos] = useState([]);
    const { t } = useTranslation();
    const submitNuevaIncidencia = async () => {
        if (!nuevaIncidencia.comentario) return toast.error(t('area-personal.incidencias.modals.nueva-incidencia.error.comentario'));
        if (!nuevaIncidencia.fotos) return toast.error(t('area-personal.incidencias.modals.nueva-incidencia.error.fotos'));

        const formData = new FormData();
        formData.append('tipo', nuevaIncidencia.tipo);
        formData.append('comentario', nuevaIncidencia.comentario);
        formData.append('pedidoId', pedido.id);
        formData.append('piezaId', nuevaIncidencia.piezaId);
        formData.append('proveedor_id', nuevaIncidencia.proveedor_id);

        for (let i = 0; i < nuevaIncidencia.fotos.length; i++) {
            formData.append('fotos', nuevaIncidencia.fotos[i]);
        }

        setIsLoading(true);

        // petició back
        const res = await apiRequest.user.createNewIncident(formData);
        if (res?.success) {
            let newArrPedidos = [];
            if (pedido?.piezas.length > 1) {
                const newArrPiezas = pedido?.piezas?.map(pieza => {
                    return pieza?.id === nuevaIncidencia.piezaId
                        ? { ...pieza, incident: 1, incident_id: res.incident_id }
                        : pieza;
                });
                newArrPedidos = pedidosData?.map(order => {
                    return order?.id === pedido?.id ? { ...order, piezas: newArrPiezas } : order;
                });
            } else {
                newArrPedidos = pedidosData?.map(order => {
                    return order?.id === pedido?.id
                        ? { ...order, piezas: [{ ...order?.piezas[0], incident: 1, incident_id: res.incident_id }] }
                        : order;
                });
            }
            setPedidosData(newArrPedidos);
            setIsNuevaIncidenciaOpen(false);
            return setIsOkMessageOpen(true);
        }
        toast.error(t('errors.1'));
        return setIsLoading(false);
    };

    const reqGetTiposIncidencia = async () => {
        const res = await apiRequest.user.getIncidentsType();
        if (res?.success) {
            return setTiposIncidencia(res?.types);
        }
        return toast.error(t('errors.1'));
    };

    const handlePiezaId = async (e, piezaId, proveedorId) => {
        e.target.checked ? setNuevaIncidencia({ ...nuevaIncidencia, piezaId, proveedor_id: proveedorId }) : setNuevaIncidencia({ ...nuevaIncidencia, piezaId: '', proveedor_id: '' });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        reqGetTiposIncidencia();
        if (pedido?.piezas?.length > 1) {
            setStepModal(0);
        } else {
            setNuevaIncidencia({ ...nuevaIncidencia, piezaId: pedido?.piezas[0]?.id, proveedor_id: pedido?.piezas[0]?.proveedor.id });
        }
    }, []);

    useEffect(() => {
        const arrNames = [];
        if (nuevaIncidencia.fotos !== '') {
            for (let i = 0; i < nuevaIncidencia.fotos.length; i++) {
                arrNames.push(nuevaIncidencia.fotos[i].name);
            }
        }
        setNameFotos(arrNames);
    }, [nuevaIncidencia.fotos]);

    return (
        <ModalContainer
            setIsOpenModal={setIsNuevaIncidenciaOpen}
            className="modal-nueva-incidencia"
            classNameContainer={`${
                stepModal === 1 || stepModal === 0
                    ? ''
                    : 'container-second-step'
            } ${stepModal === 0 ? 'container-step0' : ''}`}
        >
            <h3 className="modal-nueva-incidencia__title">
                {t('area-personal.incidencias.nueva').toUpperCase()}
            </h3>
            {stepModal === 0
                ? <Step0
                    pedido={pedido}
                    nuevaIncidencia={nuevaIncidencia}
                    setStepModal={setStepModal}
                    handlePiezaId={handlePiezaId}
                />
                : stepModal === 1
                    ? <Step1
                        tiposIncidencia={tiposIncidencia}
                        setNuevaIncidencia={setNuevaIncidencia}
                        nuevaIncidencia={nuevaIncidencia}
                        setStepModal={setStepModal}
                    />
                    : <Step2
                        isLoading={isLoading}
                        setNuevaIncidencia={setNuevaIncidencia}
                        nuevaIncidencia={nuevaIncidencia}
                        nameFotos={nameFotos}
                        setIsConcidionesChecked={setIsConcidionesChecked}
                        submitNuevaIncidencia={submitNuevaIncidencia}
                        isConcidionesChecked={isConcidionesChecked}
                    />
            }
        </ModalContainer>
    );
};

ModalNuevaIncidencia.propTypes = {
    setIsNuevaIncidenciaOpen: PropTypes.func.isRequired,
    setIsOkMessageOpen: PropTypes.func.isRequired,
    pedido: PropTypes.object.isRequired,
    pedidosData: PropTypes.array.isRequired,
    setPedidosData: PropTypes.func.isRequired
};

export default ModalNuevaIncidencia;
