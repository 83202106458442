import useScreenWidth from '../../../../hooks/useScreenWidth';

import './skeletonPiezaCard.scss';

const SkeletonPiezaCard = () => {
    const screenWidth = useScreenWidth();

    return <div className='pieza-card skeleton-pieza-card'>
        <div className='pieza-card__img pieza-card-loading-animation'>
        </div>
        <div className='pieza-card__info'>
            <h3 className='pieza-card__info--nombre skeleton-pieza-card__nombre pieza-card-loading-animation'></h3>
            <p className='pieza-card__info--modelo skeleton-pieza-card__modelo pieza-card-loading-animation'></p>
            {screenWidth >= 992 &&
                <div className='pieza-card__info--containerdesk'>
                    <p className='skeleton-pieza-card__labels'>
                        <span className='skeleton-pieza-card__labels--label1 pieza-card-loading-animation'></span>
                        <span className='skeleton-pieza-card__labels--label2 pieza-card-loading-animation'></span>
                    </p>
                    <div className='skeleton-pieza-card__observaciones pieza-card-loading-animation'></div>
                </div>
            }
            <h3 className='pieza-card__info--precio skeleton-pieza-card__precio pieza-card-loading-animation'></h3>
            {screenWidth >= 992 && <p className='skeleton-pieza-card__precio2 pieza-card-loading-animation'></p>}
        </div>
        <p className='pieza-card__disponible skeleton-pieza-card__disponible pieza-card-loading-animation'></p>
        <p className='pieza-card__cart skeleton-pieza-card__cart pieza-card-loading-animation'></p>
    </div>;
};

export default SkeletonPiezaCard;
