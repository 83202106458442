import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { GoAlertFill } from 'react-icons/go';
import CardEachSeguimiento from './CardEachSeguimiento';
import box from '../../../../../images/area-personal/box.png';

import './modalSeguimiento.scss';
import { useEffect, useState } from 'react';
import useScreenWidth from '../../../../../hooks/useScreenWidth';
import { apiRequest } from '../../../../../services/api';
import SkeletonDirSeguimiento from './SkeletonDirSeguimiento';
import SkeletonCardEachSeguimiento from './SkeletonCardEachSeguimiento';
import { toast } from 'react-toastify';
import ModalContainer from '../../../../shared/ModalContainer';

const ModalSeguimiento = ({ setIsSeguimientoOpen, pedido }) => {
    const { t } = useTranslation();
    const screenWidth = useScreenWidth();
    const [infoSeguimiento, setInfoSeguimiento] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const reqGetTraking = async (checkoutId) => {
        setIsLoading(true);
        const res = await apiRequest.user.getTracking({ checkoutId });
        if (res?.success) {
            setInfoSeguimiento(res);
        } else {
            toast.error(t('errors.1'));
        }
        return setIsLoading(false);
    };

    useEffect(() => {
        reqGetTraking(pedido?.checkout_id);
        window.scrollTo(0, 0);
    }, []);

    return (
        <ModalContainer setIsOpenModal={setIsSeguimientoOpen} className='modal-seguimiento'>
            <h2>{t('area-personal.pedidos.modals.seguimiento.title')}</h2>
            <h5>{t('area-personal.pedidos.modals.seguimiento.direccion')}</h5>
            {!isLoading
                ? <>
                    <h4>{infoSeguimiento?.direccion_entrega?.nombre}</h4>
                    <h4>{infoSeguimiento?.direccion_entrega?.telefono}</h4>
                    <h4>{infoSeguimiento?.direccion_entrega?.direccion}</h4>
                    <h4>{infoSeguimiento?.direccion_entrega?.codigo_postal} {infoSeguimiento?.direccion_entrega?.poblacion}</h4>
                    <h4>{infoSeguimiento?.direccion_entrega?.area}, {infoSeguimiento?.direccion_entrega?.pais?.nombre}</h4>
                </>
                : <SkeletonDirSeguimiento/>}

            {infoSeguimiento?.envios?.length > 1
                ? <>
                    <p className='modal-seguimiento__alert'><GoAlertFill/> {t('area-personal.pedidos.modals.seguimiento.warning')}</p>
                    <p className='modal-seguimiento__paquetes'>{infoSeguimiento?.envios?.length} {t('area-personal.pedidos.modals.seguimiento.paquetes')}</p>
                </>
                : <p className='modal-seguimiento__one-paquete'></p>}

            <p className='modal-seguimiento__titles'>
                <span>{t('area-personal.pedidos.modals.seguimiento.numEnvio')}</span>
                <span>{t('area-personal.pedidos.modals.seguimiento.contenido')}</span>
                {screenWidth >= 992 &&
                    <>
                        <span className='modal-seguimiento__titles--center'>{t('area-personal.pedidos.modals.seguimiento.ref')}</span>
                        <span className='modal-seguimiento__titles--center'>{t('area-personal.pedidos.modals.seguimiento.transportado')}</span>
                        <span className='modal-seguimiento__titles--center'>{t('area-personal.pedidos.modals.seguimiento.estado')}</span>
                    </>
                }
            </p>
            {!isLoading
                ? <>
                    {infoSeguimiento?.envios?.length > 0
                        ? infoSeguimiento?.envios?.map((envio, idx) => {
                            return screenWidth < 1200
                                ? <CardEachSeguimiento key={envio?.codigo_transporte + idx} envio={envio} />
                                : <div key={envio?.codigo_transporte + idx} className='modal-seguimiento__desktop'>
                                    <span className='modal-seguimiento__desktop--icon'>
                                        <img src={box} alt='box'/>
                                    </span>
                                    <CardEachSeguimiento envio={envio} />
                                </div>;
                        })
                        : screenWidth < 1200
                            ? <div className='modal-seguimiento__no-data'>{t('area-personal.pedidos.modals.seguimiento.no-data')}</div>
                            : <div className='modal-seguimiento__desktop'>
                                <span className='modal-seguimiento__desktop--icon'>
                                    <img src={box} alt='box'/>
                                </span>
                                <div className='modal-seguimiento__no-data'>{t('area-personal.pedidos.modals.seguimiento.no-data')}</div>
                            </div>
                    }
                </>
                : screenWidth < 1200
                    ? <SkeletonCardEachSeguimiento/>
                    : <div className='modal-seguimiento__desktop'>
                        <span className='modal-seguimiento__desktop--icon'>
                            <img src={box} alt='box'/>
                        </span>
                        <SkeletonCardEachSeguimiento />
                    </div>}
        </ModalContainer>);
};

ModalSeguimiento.propTypes = {
    setIsSeguimientoOpen: PropTypes.func,
    pedido: PropTypes.object
};

export default ModalSeguimiento;
