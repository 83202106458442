import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './pedidoCard.scss';
import { useState, useEffect } from 'react';
import { TbProgressAlert } from 'react-icons/tb';
import { HiClipboardList } from 'react-icons/hi';
import { MdLocalShipping, MdSdCardAlert, MdSimCardDownload } from 'react-icons/md';
import { apiRequest } from '../../../../../services/api';
import { toast } from 'react-toastify';
import Spinner from '../../../../shared/Spinner';
import useScreenWidth from '../../../../../hooks/useScreenWidth';
import ActionsMenu from './ActionsMenu';

const PedidoCard = ({ pedido, setIsSeguimientoOpen, setIsDetallesPedidoOpen, startNuevaIncidencia, setInfoToShow, setIsVerIncidenciaOpen }) => {
    const { t } = useTranslation();
    const [isLimitIncidents, setIsLimitIncidents] = useState(false);
    const [partsWithIncidents, setPartsWithIncidents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const screenWidth = useScreenWidth();

    useEffect(() => {
        setPartsWithIncidents(pedido?.piezas?.filter(pieza => pieza?.incident !== 0));
        if (pedido?.piezas?.length > 1) {
            const partsWithoutIncident = pedido?.piezas?.filter(pieza => pieza?.incident === 0);
            return setIsLimitIncidents(!partsWithoutIncident?.length > 0);
        }
        setIsLimitIncidents(pedido?.piezas[0]?.incident !== 0);
    }, [pedido.piezas]);

    const handleDownloadInvoices = async () => {
        if (isLoading) return;
        setIsLoading(true);
        const res = await apiRequest.user.getInvoicesPdf(pedido.facturas);
        if (res?.success) {
            for (const invoice of res.invoices) {
                const linkSource = `data:application/pdf;base64,${invoice.pdfBase64}`;
                const downloadLink = document.createElement('a');
                const fileName = invoice?.numFactura + '.pdf';
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        } else {
            toast.error(t('errors.1'));
        }
        return setIsLoading(false);
    };

    return <div className='pedido-card'>
        <div className='pedido-card__column left-align'>
            <span className='pedido-card__column--id' onClick={() => {
                setIsDetallesPedidoOpen(true);
                setInfoToShow(pedido);
            }}>{pedido?.id} <HiClipboardList /></span>
            <span className='pedido-card__column--fecha'>{pedido?.fecha}</span>
        </div>
        <div className='pedido-card__column triple-column left-align'>
            {
                pedido?.piezas?.map(pieza => {
                    return (
                        <div key={pieza?.id} className='triple-column-div'>
                            <span className='pedido-card__column--nombre'>{pieza?.tipo_pieza}</span>
                            <span className='pedido-card__column--modelo'>{pieza?.marca} {pieza?.modelo}</span>
                        </div>);
                })
            }
        </div>
        <div className='pedido-card__column'>
            <span className='pedido-card__column--title'>{t('area-personal.pedidos.precio')}</span>
            <span className='pedido-card__column--precio'>{pedido?.total}€</span>
        </div>
        <div className='pedido-card__column'>
            <span className='pedido-card__column--title'>{t('area-personal.pedidos.envio')}</span>
            <span className='pedido-card__column--precio'>{pedido?.total_envio}€</span>
        </div>
        <div className='pedido-card__column doble-column'>
            <span className='pedido-card__column--title'>{t('area-personal.pedidos.total')}</span>
            <span className='pedido-card__column--precio'>{pedido?.total_iva}€</span>
        </div>
        {screenWidth >= 1200
            ? <ActionsMenu pedido={pedido} setIsSeguimientoOpen={setIsSeguimientoOpen} setInfoToShow={setInfoToShow} isLimitIncidents={isLimitIncidents} startNuevaIncidencia={startNuevaIncidencia} partsWithIncidents={partsWithIncidents} setIsVerIncidenciaOpen={setIsVerIncidenciaOpen} handleDownloadInvoices={handleDownloadInvoices} isLoading={isLoading} />
            : <div className='pedido-card__acciones'>
                <div onClick={() => {
                    setIsSeguimientoOpen(true);
                    setInfoToShow(pedido);
                }}>
                    <p><MdLocalShipping /></p>
                    <p>{t('area-personal.pedidos.seguimiento')}</p>
                </div>
                {!isLimitIncidents && <div
                    onClick={() => startNuevaIncidencia(pedido)}>
                    <p><MdSdCardAlert /></p>
                    <p>{t('area-personal.pedidos.incidencia')}</p>
                </div>}
                {partsWithIncidents?.length > 0 && <div
                    onClick={() => {
                        setIsVerIncidenciaOpen(true);
                        setInfoToShow(partsWithIncidents);
                    }}>
                    <p><TbProgressAlert /></p>
                    <p>{t('area-personal.incidencias.ver')}</p>
                </div>}
                <div onClick={handleDownloadInvoices}>
                    <p>{isLoading ? <Spinner className='spinner-descargar-factura'/> : <MdSimCardDownload />}</p>
                    <p>{pedido.facturas.length > 1 ? t('area-personal.pedidos.descargar-more') : t('area-personal.pedidos.descargar')}</p>
                </div>
            </div>}
    </div>;
};

PedidoCard.propTypes = {
    pedido: PropTypes.object.isRequired,
    setIsSeguimientoOpen: PropTypes.func,
    setIsDetallesPedidoOpen: PropTypes.func,
    setInfoToShow: PropTypes.func,
    startNuevaIncidencia: PropTypes.func,
    setIsVerIncidenciaOpen: PropTypes.func
};

export default PedidoCard;
