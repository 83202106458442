import { useTranslation } from 'react-i18next';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import './favoritos.scss';
import FavoritosCard from './FavoritosCard';
import { useSelector } from 'react-redux';
import useScreenWidth from '../../../../hooks/useScreenWidth';

const Favoritos = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const screenWidth = useScreenWidth();
    const favoritosArr = useSelector(state => state.favorites);
    return (
        <div className='favoritos'>
            <span
                className='favoritos__return'
                onClick={() => navigate('/area-personal')}>
                <BiArrowBack/>
            </span>
            <h2>{t('area-personal.favoritos.title')}</h2>
            <section className='favoritos__list'>
                <p className='favoritos__list--titles'>
                    <span>{t('area-personal.favoritos.producto')}</span>
                    {screenWidth >= 1200 && <span className='favoritos__list--titles--estado-stock'>{t('area-personal.favoritos.estado')}</span>}
                    <span className='favoritos__list--titles--precio'>{t('area-personal.favoritos.precio')}</span>
                </p>
                <div className='favoritos__list--cards'>
                    {favoritosArr?.length > 0
                        ? favoritosArr.map(pieza =>
                            <FavoritosCard key={pieza?.id} pieza={pieza}/>)
                        : <div className='favoritos__list--no-data'><span>{t('area-personal.favoritos.no-fav')}</span></div>
                    }
                </div>
            </section>
        </div>
    );
};

export default Favoritos;
