import { useDispatch, useSelector } from 'react-redux';
import { addToFavorites, removeToFavorites } from '../../../redux/actions/favorites';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './addToFavoritosBtn.scss';
import { useOutletContext } from 'react-router-dom';
import { apiRequest } from '../../../services/api';

const AddToFavoritosBtn = ({ pieza, classNameBtn = '', classNameDisabled = '' }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const favoritosArr = useSelector(state => state.favorites);
    const cart = useSelector(state => state.cart);
    const user = useSelector(state => state.auth);
    const { setIsOpenAuthModal } = useOutletContext();

    const deleteToFavorites = async () => {
        const res = await apiRequest.user.deleteToFavoritesRequest({ piezaStockId: pieza?.id });
        if (res?.success) {
            return dispatch(removeToFavorites(pieza?.id));
        }
        return toast.error(t('errors.favoritos.2'));
    };

    const handleAddToFavorites = async (e) => {
        e.stopPropagation();
        if (cart?.piezas?.find(el => el?.pieza_integracion_id === pieza?.id)) return;
        if (!user) return setIsOpenAuthModal('login');

        const isAlreadyAdded = favoritosArr?.find(el => el?.id === pieza?.id);
        if (!isAlreadyAdded) {
            const res = await apiRequest.user.addToFavoritesRequest({ pieza });
            if (res?.success) {
                return dispatch(addToFavorites(pieza));
            }
            return toast.error(t('errors.favoritos.1'));
        }
        return deleteToFavorites();
    };

    return (
        <p className={`add-to-favoritos-btn ${classNameBtn} ${cart?.piezas?.find(el => el?.pieza_integracion_id === pieza?.id) ? (classNameDisabled + ' add-to-favoritos-btn__disabled') : ''}`} onClick={handleAddToFavorites}>
            {favoritosArr && favoritosArr.find(el => el?.id === pieza?.id)
                ? <span className='add-to-favoritos-btn__added'>{t('area-personal.favoritos.btn-active')}</span>
                : <span>{t('area-personal.favoritos.btn')}</span>
            }
        </p>
    );
};

AddToFavoritosBtn.propTypes = {
    pieza: PropTypes.object.isRequired,
    classNameBtn: PropTypes.string,
    classNameDisabled: PropTypes.string
};

export default AddToFavoritosBtn;
