import PropTypes from 'prop-types';
import imgDefault from '../../../../../images/placeholder.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import './mobCarousel.scss';
import Image from '../../../../shared/Image';

const MobCarousel = ({ fotos }) => {
    return <div className="mySwiper">
        <div>
            {/* <p className='mySwiper__text'>
                <span className='mySwiper__text--stock'>En stock</span>
                <span className='mySwiper__text--discount'>-5% DTO.</span>
            </p> */}
            {fotos?.length > 0
                ? <Swiper
                    cssMode={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                >
                    {fotos?.map((foto, idx) => (
                        <SwiperSlide key={idx} >
                            <Image foto={foto} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                : <div className='mySwiper__img'><img src={imgDefault} alt='img-default' className='img-default'/></div>}
        </div>
    </div>;
};

MobCarousel.propTypes = {
    fotos: PropTypes.array
};

export default MobCarousel;
