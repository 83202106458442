import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const EstadoRow = ({ incidencia }) => {
    const { t } = useTranslation();
    return (
        <p className='modal-ver-incidencia__container--card--row-estado'>
            <span className='modal-ver-incidencia__container--card--row-estado--fecha'>{incidencia?.fecha}</span>
            <span className={`modal-ver-incidencia__container--card--row-estado--${incidencia?.estado?.id === 2 ? 'primary-color' : 'black-color'}`}>
                {(incidencia?.estado?.nombre?.toUpperCase())}
            </span>
            <span className='modal-ver-incidencia__container--card--row-estado--comentario'>
                <span className='modal-ver-incidencia__container--card--row-estado--comentario--title'>{t('area-personal.incidencias.modals.ver-incidencia.detalle')}</span>{(incidencia?.comentario || incidencia?.tipo_incidencia)}
            </span>
        </p>
    );
};

EstadoRow.propTypes = {
    incidencia: PropTypes.object.isRequired
};

export default EstadoRow;
