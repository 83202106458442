import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import DropDownCard from '../DropDownCard';

import './cardEachSeguimiento.scss';

const CardEachSeguimiento = ({ envio }) => {
    const { t } = useTranslation();
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    return <div className='card-each-seguimiento'>
        <div className='card-each-seguimiento__container'>
            <div className={`card-each-seguimiento__container--info ${isDropDownOpen ? 'dropdown-seguimiento-open' : ''}`}>
                <p>
                    <span className='card-each-seguimiento__container--info--num-envio'>{envio?.codigo_transporte}</span>
                    <span className='card-each-seguimiento__container--info--fecha'>{envio?.fecha}</span>
                </p>
                <p className='card-each-seguimiento__container--info--contenido'>{envio?.piezas[0]?.nombre}</p>
                <p>
                    <span className='card-each-seguimiento__container--info--title'>{t('area-personal.pedidos.modals.seguimiento.ref')}</span>
                    <span className='card-each-seguimiento__container--info--ref'>{envio?.numero_seguimiento}</span>
                </p>
                <p>
                    <span className='card-each-seguimiento__container--info--title'>{t('area-personal.pedidos.modals.seguimiento.transportado')}</span>
                    <span className='card-each-seguimiento__container--info--transporte'>{envio?.transportista?.nombre}</span>
                </p>
                <p className='card-each-seguimiento__container--info--estado-container'>
                    <span className='card-each-seguimiento__container--info--title'>{t('area-personal.pedidos.modals.seguimiento.estado')}</span>
                    <span className={`card-each-seguimiento__container--info--estado-container--estado ${(envio?.estado?.nombre || envio?.estado[0]?.nombre).toUpperCase() === 'ENTREGADO' ? 'entregado' : ''}`}>{(envio?.estado?.nombre || envio?.estado[0]?.nombre).toUpperCase()}</span>
                </p>

            </div>
            <span
                className={`card-each-seguimiento__container--arrow ${isDropDownOpen ? 'dropdown-seguimiento-open' : ''}`}
                onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
                {isDropDownOpen ? <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
            </span>
        </div>
        {isDropDownOpen && <DropDownCard envio={envio} />}
    </div>;
};

CardEachSeguimiento.propTypes = {
    envio: PropTypes.object.isRequired
};

export default CardEachSeguimiento;
