import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { apiRequest } from '../../../../services/api';
import { useRef, useState } from 'react';
import Spinner from '../../Spinner';
import { handleErrorMessage, handleValidPhone } from '../../../../utils';
import FormInput from '../../FormInput';
import FormPasswordInput from '../../FormPasswordInput';

const FormRegister = ({ setTokenVerifyRegister, setIsOpenAuthModal, setEmail }) => {
    const { t } = useTranslation();
    const emailRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm();
    const {
        handleSubmit,
        clearErrors,
        setValue,
        setError
    } = methods;

    const onSubmit = async (data) => {
        if (data.password !== data.passwordconfirm) {
            return setError('passwordconfirm', {
                message: 'confirm'
            });
        }
        setIsLoading(true);
        const res = await apiRequest.auth.register(data);
        if (res?.success) {
            setEmail(data.email);
            setTokenVerifyRegister(res.tokenVerify);
            return setIsOpenAuthModal('verify-register');
        }
        const errorMessage = handleErrorMessage(res?.error);
        toast.error(errorMessage);
        return setIsLoading(false);
    };

    const handleAutocomplete = () => {
        setValue('email', emailRef.current.value);
    };

    return (
        <div className='form-login'>
            <div className='form-login__container form-register'>
                <h3 className='form-login__container--title form-register'>
                    {t('registro.title')}
                </h3>
                <FormProvider {...methods} >
                    <form
                        className='form-login__container--form form-register'
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormInput
                            classNameError='form-login__container--form--error'
                            name='nombre'
                            placeholder={t(
                                'registro.form.nombre-placeholder'
                            )}/>
                        <FormInput
                            classNameError='form-login__container--form--error'
                            name='telefono'
                            pattern={{ value: /^\S.*[0-9]+$/ }}
                            minLength={{ value: 9, message: 'minLength' }}
                            onKeyDown={handleValidPhone} />
                        <FormInput
                            name='email'
                            type='email'
                            pattern={{
                                value: /\S+@\S+\.\S+/,
                                message: 'formato'
                            }}
                            inputRef={emailRef}
                            onChange={() => clearErrors('email')}
                            classNameError="form-login__container--form--error"
                        />
                        <FormPasswordInput
                            name='password'
                            label={t('login.form.contraseña')}
                            className="form-login__container--form"
                        />
                        <FormPasswordInput
                            name='passwordconfirm'
                            label={t('registro.form.contraseña-repeat')}
                            className="form-login__container--form"
                        />
                        <button onClick={handleAutocomplete} type='submit'>
                            {isLoading
                                ? <Spinner className='spinner-login' />
                                : t('registro.form.continuar')
                            }
                        </button>
                    </form>
                </FormProvider>
                <p className='form-login__container--nocuenta form-register'>
                    <span>{t('registro.form.ya-cuenta')}</span>
                    <span
                        className='form-login__container--nocuenta--link'
                        onClick={() => setIsOpenAuthModal('login')}
                    >
                        {t('registro.form.login')}
                    </span>
                </p>
            </div>
        </div>
    );
};

FormRegister.propTypes = {
    setTokenVerifyRegister: PropTypes.func,
    setIsOpenAuthModal: PropTypes.func,
    setEmail: PropTypes.func
};

export default FormRegister;
