import API from './apiConfig';

export const apiRequest = {
    auth: {
        login: async (data) => {
            return await API
                .post('/auth/login', data).then(res => res.data)
                .catch(error => error.response.data);
        },
        loginByToken: async (data) => {
            return await API
                .post('/auth/loginbytoken', data).then(res => res.data)
                .catch(error => error.response.data);
        },
        register: async (data) => {
            return await API
                .post('/auth/register', data).then(res => res.data)
                .catch(error => error.response.data);
        },
        confirmRegister: async (data) => {
            return await API
                .post('/auth/confirm-register', data).then(res => res.data)
                .catch(error => error.response.data);
        },
        sendCodeConfirm: async (data) => {
            return await API
                .post('/auth/send-code-confirm-register', data).then(res => res.data)
                .catch(error => error.response.data);
        },
        resetPassword: async (email) => {
            return await API
                .post('/auth/resetpassword', { email }).then(res => res.data)
                .catch(error => error.response.data);
        },
        restartPassword: async (formData) => {
            return await API
                .post('/auth/restartpassword', formData).then(res => res.data)
                .catch(error => error.response.data);
        }
    },
    stock: {
        getPiezaById: async (piezaId) => {
            return await API
                .get(`/stock/pieza/${piezaId}`).then(res => res.data)
                .catch(error => error.response);
        },
        stockRequest: async (params) => {
            return await API
                .get('/stock', { params }).then(res => res.data)
                .catch(error => error.response);
        },
        getStockStatus: async (piezaId) => {
            return await API
                .get(`/stock/status/${piezaId}`).then(res => res.data)
                .catch(error => error.response);
        },
        filtrosRequest: async (filtro, params) => {
            return await API
                .get(`/stock/${filtro}`, { params }).then(res => res.data)
                .catch(error => error.response);
        }
    },
    cart: {
        createGuestCheckout: async (cartParts) => {
            return await API
                .post('/cart/createguestcheckout', cartParts).then(res => res.data)
                .catch(error => error.response);
        },
        createUserCheckout: async (cartParts) => {
            return await API
                .post('/cart/createusercheckout', cartParts).then(res => res.data)
                .catch(error => error.response);
        }
    },
    user: {
        getPaises: async () => {
            return await API
                .get('/users/paises').then(res => res.data)
                .catch(error => error.response);
        },
        getUserData: async () => {
            return await API
                .get('/users/userdata').then(res => res.data)
                .catch(error => error.response);
        },
        updateUserData: async (newData) => {
            return await API
                .post('/users/updateuserdata', newData).then(res => res.data)
                .catch(error => error.response);
        },
        getAddresses: async () => {
            return await API
                .get('/users/addresses').then(res => res.data)
                .catch(error => error.response);
        },
        addNewAddress: async (newAddress) => {
            return await API
                .post('/users/newaddress', newAddress).then(res => res.data)
                .catch(error => error.response);
        },
        deleteAddress: async (params) => {
            return await API
                .delete('/users/deleteaddress', { params }).then(res => res.data)
                .catch(error => error.response);
        },
        getOrders: async (params) => {
            return await API
                .get('/users/orders', { params }).then(res => res.data)
                .catch(error => error.response);
        },
        getOrderDetails: async (params) => {
            return await API
                .get('/users/orderdetail', { params }).then(res => res.data)
                .catch(error => error.response);
        },
        getTracking: async (params) => {
            return await API
                .get('/users/tracking', { params }).then(res => res.data)
                .catch(error => error.response);
        },
        getIncidentsType: async () => {
            return await API
                .get('/users/incidentstype').then(res => res.data)
                .catch(error => error.response);
        },
        createNewIncident: async (newIncident) => {
            return await API
                .post('/users/newincident', newIncident, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => res.data)
                .catch(error => error.response);
        },
        getIncidentDetail: async (params) => {
            return await API
                .get('/users/incidentdetail', { params }).then(res => res.data)
                .catch(error => error.response);
        },
        getInvoicesPdf: async (numFacturas) => {
            return await API
                .post('/users/invoicespdf', { numFacturas }).then(res => res.data)
                .catch(error => error.response);
        },
        addToFavoritesRequest: async (piezaStockId) => {
            return await API
                .post('/users/addtofavorites', piezaStockId).then(res => res.data)
                .catch(error => error.response);
        },
        deleteToFavoritesRequest: async (params) => {
            return await API
                .delete('/users/deletetofavorites', { params }).then(res => res.data)
                .catch(error => error.response);
        }
    },
    contact: {
        sendContact: async (formData) => {
            return await API
                .post('/contact/form', formData).then(res => res.data)
                .catch(error => error.response);
        },
        orderPartNotFound: async (data) => {
            return await API
                .post('/contact/partnotfound', data).then(res => res.data)
                .catch(error => error.response);
        }
    }
};
