import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Spinner from '../../Spinner';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../../../services/api';
import { login } from '../../../../redux/actions/auth';
import { toast } from 'react-toastify';
import { handleErrorMessage } from '../../../../utils';
import { setCookie } from '../../../../services/cookies';
import FormInput from '../../FormInput';

const FormVerifyRegister = ({ token, setIsOpenAuthModal, email, setTokenVerifyRegister }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const methods = useForm();
    const {
        handleSubmit,
        setValue
    } = methods;
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSendCode, setIsLoadingSendCode] = useState(false);

    const onSubmit = async (data) => {
        setIsLoading(true);
        const res = await apiRequest.auth.confirmRegister(data);
        if (res?.success) {
            setCookie('token_piezas_recomotor', res?.token, 1);
            dispatch(login(res?.user));
            return setIsOpenAuthModal(false);
        }
        const errorMessage = handleErrorMessage(res?.error);
        toast.error(errorMessage);

        return setIsLoading(false);
    };

    const sendCode = async () => {
        if (isLoadingSendCode) return;
        setIsLoadingSendCode(true);
        const res = await apiRequest.auth.sendCodeConfirm({ token });
        if (res?.success) {
            setTokenVerifyRegister(res.tokenVerify);
            toast.success(t('registro.confirm.send-again-message'));
        } else {
            toast.error(t('errors.1'));
        }
        setIsLoadingSendCode(false);
    };

    useEffect(() => {
        setValue('token', token);
    }, []);

    return (
        <div className='form-login'>
            <div className='form-login__container verify-register'>
                <h2>{t('registro.confirm.title')}</h2>
                <p className='verify-register-subtitle'>{t('registro.confirm.subtitle')} {email}</p>
                <FormProvider {...methods} >
                    <form
                        className='form-login__container--form verify-register'
                        onSubmit={handleSubmit(onSubmit)}>
                        <FormInput
                            classNameError='form-login__container--form--error'
                            name='code'
                        />
                        <button type='submit'>
                            {isLoading
                                ? <Spinner className='spinner-login' />
                                : t('registro.confirm.submit')
                            }
                        </button>
                    </form>
                </FormProvider>
                <p className='form-login__container--nocuenta verify-register'>
                    {t('registro.confirm.conditions.1')}
                    <Link
                        className='form-login__container--nocuenta--link'
                        to={'/compra'}
                        title='Condiciones generales'
                        target='_blank'
                        rel='noreferrer'>
                        {' '}{t('registro.confirm.conditions.2')}{' '}
                    </Link>
                    {t('registro.confirm.conditions.3')}
                    <a className='form-login__container--nocuenta--link' href='https://recomotor.com/aviso-legal' target='_blank' rel='noreferrer'>
                        {' '}{t('registro.confirm.conditions.4')}{' '}
                    </a>
                    {t('registro.confirm.conditions.5')}
                    <a className='form-login__container--nocuenta--link' href='https://recomotor.com/politica-de-cookies' target='_blank' rel='noreferrer'>
                        {' '}{t('registro.confirm.conditions.6')}{' '}
                    </a>
                    {t('registro.confirm.conditions.7')}
                    <a className='form-login__container--nocuenta--link' href='https://recomotor.com/politica-de-privacidad' target='_blank' rel='noreferrer'>
                        {' '}{t('registro.confirm.conditions.8')}
                    </a>
                </p>
                <div className='verify-register-send-again'>
                    <p onClick={sendCode}>{t('registro.confirm.send-again')}</p>
                </div>
            </div>
        </div>
    );
};

FormVerifyRegister.propTypes = {
    token: PropTypes.string,
    setIsOpenAuthModal: PropTypes.func,
    email: PropTypes.string,
    setTokenVerifyRegister: PropTypes.func
};

export default FormVerifyRegister;
