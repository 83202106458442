import { FiDownload } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { MdVideoFile, MdInsertDriveFile } from 'react-icons/md';
import { BiSolidFilePdf } from 'react-icons/bi';
const EachFile = ({ file, handleDownload }) => {
    const extensions = {
        pdf: < BiSolidFilePdf/>,
        mp4: < MdVideoFile />,
        txt: < MdInsertDriveFile />
    };
    return (
        <div className='modal-ver-incidencia__container--card--prueba--container--card'>
            <span onClick={() => handleDownload(file)} className='modal-ver-incidencia__container--card--prueba--container--card--icon'>
                <FiDownload />
            </span>
            { (file?.ext === 'jpg' || file?.ext === 'png' || file?.ext === 'jpeg' || file?.ext === 'gif')
                ? <img src={'data:image/jpeg;base64,' + file?.data} alt={file?.name} className='modal-ver-incidencia__container--card--prueba--container--card--img'/>
                : <p className='modal-ver-incidencia__container--card--prueba--container--card--noimg'>
                    <span className='modal-ver-incidencia__container--card--prueba--container--card--noimg--icon'>{extensions[file?.ext] || extensions.txt}</span>
                    {file?.name}</p>
            }
        </div>
    );
};

EachFile.propTypes = {
    file: PropTypes.object.isRequired,
    handleDownload: PropTypes.func.isRequired
};

export default EachFile;
