import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './noDataCard.scss';
import NoDataBtns from '../../../shared/NoDataBtns';

const NoDataCard = ({ filterValues, searchParams }) => {
    const { t } = useTranslation();

    return (
        <div className='no-data-card'>
            <p className='no-data-card__text'>
                <span className='no-data-card__text--1'>{t('catalogo-piezas.no-data.1')}</span>
                <span className='no-data-card__text--2'>{t('catalogo-piezas.no-data.2')}</span>
                <span className='no-data-card__text--3'>{t('catalogo-piezas.no-data.3')}</span>
            </p>
            <NoDataBtns className='no-data-card__icons' filterValues={filterValues} searchParams={searchParams} />
        </div>
    );
};

NoDataCard.propTypes = {
    searchParams: PropTypes.string.isRequired,
    filterValues: PropTypes.object.isRequired
};

export default NoDataCard;
