import i18n from 'i18next';
import { FaGlobe } from 'react-icons/fa6';
import './languageBtn.scss';

const LanguageBtn = () => {
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    return (
        <div className="change-language">
            <FaGlobe/>
            <select className="change-language__select" defaultValue={i18n.language} name="language" onChange={(e) => changeLanguage(e.target.value)}>
                <option value='es-ES'>ES</option>
                <option value='en-EN'>EN</option>
                <option value='pt-PT'>PT</option>
            </select>
        </div>
    );
};

export default LanguageBtn;
