import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import './modalContainer.scss';

const ModalContainer = ({ children, setIsOpenModal, className = '', classNameContainer = '' }) => {
    const [delayOpen, setDelayOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setDelayOpen(true);
    }, []);

    return (
        <>
            <div
                className={`modal-container ${className} ${delayOpen ? 'modal-container--open' : ''}`}
            >
                <div className={`modal-container__container ${classNameContainer}`}>
                    <CgClose
                        className="modal-container__container--close"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsOpenModal(false);
                        }}
                    />

                    {children}
                </div>
                <div
                    className="modal-container__overlay-to-close"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsOpenModal(false);
                    }}
                />
            </div>
            <div className="modal-container__overlay" />
        </>
    );
};

ModalContainer.propTypes = {
    children: PropTypes.node,
    setIsOpenModal: PropTypes.func,
    className: PropTypes.string,
    classNameContainer: PropTypes.string
};

export default ModalContainer;
