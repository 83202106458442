import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import arrow from '../../../images/catalogo-piezas/Vector1.png';
import FloatingWhatsApp from '../FloatingWhatsApp';

import './whatsAppSection.scss';

const WhatsAppSection = ({ className = '' }) => {
    const { t } = useTranslation();
    return <div className={`whatsapp-section ${className}`}>
        <div className='whatsapp-section__message'>
            <p>
                <span><b>{t('whatsapp.no-encuentra')}</b></span>{' '}
                <span>{t('whatsapp.te-ayudamos')}</span>
            </p>
            <img src={arrow} alt='arrow' />
        </div>
        <FloatingWhatsApp />
    </div>;
};

WhatsAppSection.propTypes = {
    className: PropTypes.string
};

export default WhatsAppSection;
