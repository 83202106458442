export const types = {
    login: '[Auth] Login',
    logout: '[Auth] Logout',
    updateUserData: '[Auth] Update',

    updateCart: '[Cart] Actualizar carrito de usuario',
    addToCart: '[Cart] Añadir al carrito',
    removeToCart: '[Cart] Eliminar del carrito',

    updateFavorites: '[Favorites] Actualizar favoritos',
    addToFavorites: '[Favorites] Añadir a favoritos',
    removeToFavorites: '[Favorites] Elimminar de favoritos',

    saveFilters: '[Filters] Guardar filtros',
    resetFilters: '[Filters] Resetear filtros'
};
