import React from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

import './skeletonCardEachSeguimiento.scss';

const SkeletonCardEachSeguimiento = () => {
    return (
        <div className='card-each-seguimiento skeleton-card-each-seguimiento'>
            <div className='card-each-seguimiento__container'>
                <div className='card-each-seguimiento__container--info'>
                    <p>
                        <span className='card-each-seguimiento__container--info--num-envio skeleton-card-each-seguimiento__envio each-seguimiento-loading-animation'></span>
                        <span className='card-each-seguimiento__container--info--fecha skeleton-card-each-seguimiento__fecha each-seguimiento-loading-animation'></span>
                    </p>
                    <p className='card-each-seguimiento__container--info--contenido skeleton-card-each-seguimiento__contenido'>
                        <span className='skeleton-card-each-seguimiento__contenido--text1 each-seguimiento-loading-animation'></span>
                        <span className='skeleton-card-each-seguimiento__contenido--text2 each-seguimiento-loading-animation'></span>
                    </p>
                    <p>
                        <span className='card-each-seguimiento__container--info--title skeleton-card-each-seguimiento__title each-seguimiento-loading-animation'></span>
                        <span className='card-each-seguimiento__container--info--ref skeleton-card-each-seguimiento__ref each-seguimiento-loading-animation'></span>
                    </p>
                    <p>
                        <span className='card-each-seguimiento__container--info--title skeleton-card-each-seguimiento__title each-seguimiento-loading-animation'></span>
                        <span className='card-each-seguimiento__container--info--transporte skeleton-card-each-seguimiento__transporte each-seguimiento-loading-animation'></span>
                    </p>
                    <p className='card-each-seguimiento__container--info--estado-container'>
                        <span className='card-each-seguimiento__container--info--title skeleton-card-each-seguimiento__title each-seguimiento-loading-animation'></span>
                        <span className='card-each-seguimiento__container--info--estado-container--estado each-seguimiento-loading-animation'></span>
                    </p>

                </div>
                <span
                    className='card-each-seguimiento__container--arrow'>
                    <MdKeyboardArrowDown/>
                </span>
            </div>
        </div>
    );
};

export default SkeletonCardEachSeguimiento;
