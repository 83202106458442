import { types } from '../types';

export const authReducer = (state = null, action) => {
    switch (action.type) {
    case types.login:
        return action.payload;
    case types.logout:
        return null;
    case types.updateUserData:
        return { ...state, ...action.payload };
    default:
        return state;
    }
};
