import { useTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

const FormInput = ({
    className = '',
    classNameSize = '',
    classNameError = '',
    name,
    isRequired = true,
    maxLength = { value: 150, message: 'maxLength' },
    minLength,
    pattern,
    type = 'text',
    onChange,
    onKeyDown,
    onClick,
    isDisabled = false,
    inputRef = null,
    placeholder = ''
}) => {
    const { t } = useTranslation();
    const { register, formState: { errors }, setValue, getValues } = useFormContext();

    return (
        <div className={`${className}__div${classNameSize}`}>
            <label className={`${className}__label`}>
                {t(`input-form.name.${name}`)}
                <input
                    type={type}
                    {...register(name, {
                        maxLength: { ...maxLength },
                        required: isRequired ? 'required' : false,
                        minLength: { ...minLength },
                        pattern: { ...pattern }
                    })}
                    onChange={(e) => {
                        setValue(name, e.target.value);
                        onChange && onChange(e);
                    }}
                    onKeyDown={(e) => {
                        onKeyDown && onKeyDown(e);
                    }}
                    onClick={(e) => {
                        onClick && onClick(e);
                    }}
                    disabled={isDisabled}
                    ref={inputRef}
                    placeholder={placeholder}
                    defaultValue={getValues(name)}
                />
            </label>
            {errors[name] && (
                <span role="alert" className={classNameError}>
                    <MdCancel />{' '}
                    {t(`input-form.errors.${name}.${errors[name].message}`)}
                </span>
            )}
        </div>
    );
};

FormInput.propTypes = {
    className: PropTypes.string,
    classNameSize: PropTypes.string,
    classNameError: PropTypes.string,
    name: PropTypes.string,
    isRequired: PropTypes.bool,
    maxLength: PropTypes.object,
    minLength: PropTypes.object,
    pattern: PropTypes.object,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.any,
    isDisabled: PropTypes.bool,
    inputRef: PropTypes.any,
    placeholder: PropTypes.string
};

export default FormInput;
