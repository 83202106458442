import { types } from '../types';

export const favoritesReducer = (state = [], action) => {
    switch (action.type) {
    case types.updateFavorites:
        return action.payload;
    case types.addToFavorites:
        return [...state, action.payload];
    case types.removeToFavorites:
        return state.filter(pieza => pieza.id !== action.payload);
    default:
        return state;
    }
};
