import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './modalVerIncidencia.scss';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../../../../../services/api';
import EstadoRow from './EstadoRow';
import EachFile from './EachFile';
import SkeletonEstadoRow from './Skeletons/SkeletonEstadoRow';
import SkeletonEachFile from './Skeletons/SkeletonEachFile';

import { toast } from 'react-toastify';
import ModalContainer from '../../../../shared/ModalContainer';

const ModalVerIncidencia = ({ setIsVerIncidenciaOpen, incidenciaData }) => {
    const { t } = useTranslation();
    const [incidencia, setIncidencia] = useState(null);

    const reqGetIncidentDetail = async (pieza) => {
        const res = await apiRequest.user.getIncidentDetail({ incidenciaId: pieza?.incident_id });
        if (res?.success) {
            if (res.detail?.documentacion?.length > 0) {
                const filesDecoded = res.detail?.documentacion?.map(file => {
                    const blob = base64toBlob(file.data);
                    return { ...file, blob };
                });
                return setIncidencia((prev) => ({ ...prev, ...res.detail, documentacion: filesDecoded }));
            }
            return setIncidencia((prev) => ({ ...prev, ...res.detail }));
        } else {
            toast.error(t('errors.1'));
        }
    };

    const base64toBlob = (base64, contentType = 'application/octet-stream') => {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    useEffect(() => {
        if (incidenciaData.length === 1) {
            setIncidencia(incidenciaData[0]);
            reqGetIncidentDetail(incidenciaData[0]);
        }
        window.scrollTo(0, 0);
    }, []);

    const handleSelectIncidenciaToShow = (pieza) => {
        setIncidencia(pieza);
        reqGetIncidentDetail(pieza);
    };

    const handleDownload = async (fileData) => {
        const url = URL.createObjectURL(fileData.blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileData.name;
        link.click();
    };

    return (
        <ModalContainer setIsOpenModal={setIsVerIncidenciaOpen} className='modal-ver-incidencia'>
            {incidencia && <>
                <h2 className='modal-ver-incidencia__container--title'>{t('area-personal.incidencias.modals.ver-incidencia.title')}</h2>
                <section>
                    <p className='modal-ver-incidencia__container--titles titles-factura-pieza'>
                        <span>{t('area-personal.incidencias.pieza')}</span>
                    </p>
                    <div className='modal-ver-incidencia__container--card'>
                        <div className='modal-ver-incidencia__container--card--info'>
                            <span>{incidencia?.tipo_pieza}</span>
                            <span>{incidencia?.marca} {incidencia?.modelo}</span>
                        </div>
                        <p className='modal-ver-incidencia__container--titles titles-fecha-estado'>
                            <span>{t('area-personal.incidencias.modals.ver-incidencia.fecha')}</span>
                            <span>{t('area-personal.incidencias.estado')}</span>
                            <span className='modal-ver-incidencia__container--titles--detalle'>{t('area-personal.incidencias.modals.ver-incidencia.detalle')}</span>
                        </p>
                        {
                            incidencia.estados
                                ? incidencia.estados.length === 0
                                    ? <EstadoRow incidencia={incidenciaData} />
                                    : incidencia?.estados?.slice(0).reverse().map(incidencia => <EstadoRow incidencia={incidencia} key={incidencia?.id} />)
                                : <SkeletonEstadoRow/>
                        }
                        <div className='modal-ver-incidencia__container--card--prueba'>
                            <span className='modal-ver-incidencia__container--card--prueba--title'>
                                {t('area-personal.incidencias.modals.ver-incidencia.prueba')}
                            </span>
                            <div className='modal-ver-incidencia__container--card--prueba--container'>
                                {incidencia?.documentacion
                                    ? incidencia?.documentacion?.map((file, idx) => <EachFile key={'file' + idx} file={file} handleDownload={handleDownload} />)
                                    : <>
                                        <SkeletonEachFile/>
                                        <SkeletonEachFile/>
                                    </>
                                }
                                {incidencia?.documentacion?.length < 1 && <span className='modal-ver-incidencia__container--card--prueba--container--nodata'>{t('area-personal.incidencias.modals.ver-incidencia.nodata.1')}</span>}
                            </div>
                        </div>
                        <p className='modal-ver-incidencia__container--card--comentarios'>
                            <span className='modal-ver-incidencia__container--card--comentarios--title'>{t('area-personal.incidencias.modals.nueva-incidencia.comentarios')}</span>
                            {incidencia?.comentarios_cliente
                                ? <span className='modal-ver-incidencia__container--card--comentarios--text'>{incidencia?.comentarios_cliente}</span>
                                : incidencia?.comentarios_cliente === null
                                    ? <span className='modal-ver-incidencia__container--card--comentarios--text--nodata'>{t('area-personal.incidencias.modals.ver-incidencia.nodata.2')}</span>
                                    : <span className='skeleton-comentarios-cliente detalle-incidencia-loading-animation'></span>}
                        </p>
                    </div>
                </section>
            </>}
            {!incidencia && incidenciaData.length > 1 && <div>
                <h2 className='modal-ver-incidencia__container--title'>{t('area-personal.incidencias.modals.ver-incidencia.select')}</h2>
                <div className='modal-ver-incidencia__container--select-container'>
                    {incidenciaData.map(pieza => {
                        return (
                            <div key={pieza.id} onClick={() => handleSelectIncidenciaToShow(pieza)}>
                                <h4>{pieza.tipo_pieza}</h4>
                                <p>{pieza.marca} {pieza.modelo}</p>
                            </div>
                        );
                    })}
                </div>
            </div>}
        </ModalContainer>);
};

ModalVerIncidencia.propTypes = {
    setIsVerIncidenciaOpen: PropTypes.func.isRequired,
    incidenciaData: PropTypes.array.isRequired
};

export default ModalVerIncidencia;
