import { Outlet } from 'react-router-dom';
import Navbar from '../shared/Navbar';
import Footer from '../shared/Footer';
import { useEffect, useState } from 'react';
import { getCookie, setCookie } from '../../services/cookies';
import { apiRequest } from '../../services/api';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/actions/auth';
import { updateFavorites } from '../../redux/actions/favorites';
import AuthModal from '../shared/AuthModal';
import { decryptPartId } from '../../utils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { addToCart } from '../../redux/actions/cart';

const MainLayout = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);

    const loginByTokenReq = async (token) => {
        const res = await apiRequest.auth.loginByToken({ token });
        if (res?.success) {
            setCookie('token_piezas_recomotor', res?.token, 1);
            dispatch(login(res?.user));
            return dispatch(updateFavorites(res?.favorites));
        }
        setCookie('token_piezas_recomotor', '', -1);
    };

    const getInfoCart = async (cart) => {
        const newCart = [];
        for (const part of cart) {
            const isValidId = checkExpiredPart(part);
            if (isValidId) {
                const partInfo = await getInfoPartRequest(isValidId);
                if (partInfo) newCart.push(part);
            }
        }
        if (newCart.length === 0) {
            return localStorage.removeItem('cart');
        }
        return localStorage.setItem('cart', JSON.stringify(newCart));
    };

    const checkExpiredPart = (encryptedId) => {
        const partId = JSON.parse(decryptPartId(encryptedId));
        const currentTime = Date.now();
        const oneHour = 60 * 60 * 1000;
        return (currentTime - partId.timestamp) < oneHour ? partId.id : false;
    };

    const getInfoPartRequest = async (partId) => {
        const res = await apiRequest.stock.getPiezaById(partId);
        if (res?.success) {
            if (res?.part?.estado === 0) return false;
            const piezaData = {
                pieza_integracion_id: res?.part?.id,
                id_referencia_plataforma: res?.part?.id_original,
                nombre: res?.part?.nombre,
                tipo_pieza_id: res?.part?.tipo_pieza_id,
                referencia_pieza: res?.part?.referencia,
                precio_original: res?.part?.precio_original,
                precio_compra: res?.part?.precio_compra,
                precio_venta: res?.part?.precio_venta,
                precio_transporte: 0,
                proveedor_id: res?.part?.proveedor_id,
                casco: false,
                marca: res?.part?.marca,
                modelo: res?.part?.modelo,
                integracion_json: JSON.stringify(res?.part),
                tipo_garantia_id: res?.part?.tipo_garantia_id,
                imgs: res?.part?.imgs
            };
            dispatch(addToCart(piezaData));
            return true;
        }
        toast.error(t('errors.1'));
        return false;
    };

    useEffect(() => {
        const token = getCookie('token_piezas_recomotor');
        if (token) loginByTokenReq(token);

        const savedCart = JSON.parse(localStorage.getItem('cart'));
        if (savedCart) getInfoCart(savedCart);
    }, []);

    return (
        <div>
            <Navbar setIsOpenAuthModal={setIsOpenAuthModal} />
            <Outlet context={{ setIsOpenAuthModal }}/>
            <Footer/>
            {isOpenAuthModal && (
                <AuthModal
                    setIsOpenAuthModal={setIsOpenAuthModal}
                    formName={isOpenAuthModal}
                />
            )}
        </div>);
};

export default MainLayout;
