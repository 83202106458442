import { useTranslation } from 'react-i18next';
import ContactoForm from '../../../shared/ContactoForm';
import PropTypes from 'prop-types';

import './modalSendEmail.scss';
import ModalContainer from '../../../shared/ModalContainer';

const ModalSendEmail = ({ handleSendEmail, isLoading, formValues, setIsOpenModalSendEmail }) => {
    const { t } = useTranslation();
    return (
        <ModalContainer setIsOpenModal={setIsOpenModalSendEmail} className='modal-send-email'>
            <h2 className="modal-send-email__title">
                {t('catalogo-piezas.no-data.modal.title')}
            </h2>
            <h4 className="modal-send-email__subtitle">
                {t('catalogo-piezas.no-data.modal.subtitle')}
            </h4>
            <ContactoForm
                onSubmitContacto={handleSendEmail}
                isLoading={isLoading}
                formValues={formValues}
            />
        </ModalContainer>
    );
};

ModalSendEmail.propTypes = {
    handleSendEmail: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setIsOpenModalSendEmail: PropTypes.func.isRequired,
    formValues: PropTypes.any
};

export default ModalSendEmail;
