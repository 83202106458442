import PropTypes from 'prop-types';

import './choosePiezaCard.scss';

const ChoosePiezaCard = ({ pieza, handlePiezaId, nuevaIncidencia }) => {
    return (
        <div className='choose-pieza-card'>
            <input className='choose-pieza-card__checkbox' type='checkbox' checked={nuevaIncidencia?.piezaId === pieza?.id} onChange={(e) => handlePiezaId(e, pieza?.id, pieza?.proveedor?.id)} />
            <p className='choose-pieza-card__text'>
                <span className='choose-pieza-card__text--tipo' >{pieza?.tipo_pieza}</span>
                <span>-</span>
                <span className='choose-pieza-card__text--marca'>{pieza?.marca}</span>
                <span className='choose-pieza-card__text--modelo'>{pieza?.modelo}</span>
            </p>
        </div>
    );
};

ChoosePiezaCard.propTypes = {
    pieza: PropTypes.object.isRequired,
    handlePiezaId: PropTypes.func.isRequired,
    nuevaIncidencia: PropTypes.object.isRequired
};

export default ChoosePiezaCard;
