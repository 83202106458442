import { types } from '../types';

export const login = (usuario) => ({
    type: types.login,
    payload: usuario
});

export const logout = () => ({
    type: types.logout
});

export const updateUser = (updatedData) => ({
    type: types.updateUserData,
    payload: updatedData
});
