import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { AiFillInfoCircle } from 'react-icons/ai';
import './eachFilter.scss';

const EachFilter = ({ data, nombre, onChange, inputName, filterValues, handleRemoveFilter, isActive = false }) => {
    const { t } = useTranslation();
    const inputSearchRef = useRef(null);
    const [nombreLowCase, setNombreLowCase] = useState();
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [resultsSearchFilter, setResultsSearchFilter] = useState([]);
    const [filterSelected, setFilterSelected] = useState(null);

    const handleSearchData = (e) => {
        const resultsSearch = data?.filter(el => el?.nombre?.toLowerCase().includes(e.target.value.toLowerCase()));
        setResultsSearchFilter(resultsSearch);
    };

    const handleFilterSelected = (e, nombre) => {
        e.target.checked && setFilterSelected(nombre);
    };

    useEffect(() => {
        setNombreLowCase(nombre.toLowerCase());
        setResultsSearchFilter(data);
        data?.length > 0 && !filterValues[inputName]?.id ? setIsDropDownOpen(true) : setIsDropDownOpen(false);
    }, [data, filterValues[inputName]]);

    useEffect(() => {
        isDropDownOpen && inputSearchRef.current.focus();
    }, [isDropDownOpen]);

    return (
        <div className={'each-filter'}>
            <span className='each-filter__btn'>
                <div className={isActive ? 'each-filter__btn--active' : 'each-filter__btn--disabled'}></div>
                <span className={`each-filter__btn--nombre ${filterValues[inputName]?.id ? 'filter-selected' : ''}`}>{filterValues[inputName]?.id ? (filterSelected || filterValues[inputName]?.nombre) : nombre }</span>
                <span className='each-filter__btn--dropdown'>
                    <span className='each-filter__btn--dropdown--text'
                        onClick={() => handleRemoveFilter(inputName)}>
                        {t('catalogo-piezas.filtros-seccion.borrar')}
                    </span>
                    <span
                        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                        className='each-filter__btn--dropdown--arrow'
                    >
                        { isDropDownOpen
                            ? <MdKeyboardArrowUp />
                            : <MdKeyboardArrowDown />
                        }
                    </span>
                </span>
            </span>
            {isDropDownOpen && (
                <>
                    <ul>
                        <li className='each-filter__search-input'>
                            <input type='text' ref={inputSearchRef} onChange={handleSearchData}
                                placeholder={t('catalogo-piezas.filtros-seccion.busqueda', { nombreLowCase })}
                            />
                            {resultsSearchFilter?.length < 1 && <span
                                onClick={() => {
                                    inputSearchRef.current.value = '';
                                    setResultsSearchFilter(data);
                                }}
                                className='each-filter__search-input--reset'>✖</span>}
                        </li>
                        {resultsSearchFilter?.map((filter) => (
                            <li key={filter?.id}>
                                <input type="checkbox" id={filter?.id} name={inputName} checked={filterValues[inputName]?.id === filter?.id} onChange={(e) => {
                                    onChange(e, filter);
                                    handleFilterSelected(e, filter?.nombre);
                                    setIsDropDownOpen(false);
                                }}/>
                                <label htmlFor={filter?.id}>
                                    {filter?.nombre}
                                </label>
                            </li>
                        ))}
                        {
                            resultsSearchFilter?.length < 1 && <li className='each-filter__no-results'><span><AiFillInfoCircle /></span>{t('catalogo-piezas.filtros-seccion.no-results', { nombreLowCase })}</li>
                        }
                    </ul>
                </>
            )}
        </div>
    );
};

EachFilter.propTypes = {
    data: PropTypes.array.isRequired,
    nombre: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    inputName: PropTypes.string.isRequired,
    filterValues: PropTypes.object.isRequired,
    handleRemoveFilter: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    isPrevPagePartDetail: PropTypes.bool
};

export default EachFilter;
