import { useTranslation } from 'react-i18next';
import logo from '../../images/login/logo2.png';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaExclamation } from 'react-icons/fa6';
import Spinner from '../shared/Spinner';

import './resetPassword.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { apiRequest } from '../../services/api';
import { OkMessage } from '../shared/Alert';
import { toast } from 'react-toastify';
import { setCookie } from '../../services/cookies';
import { GiCheckMark } from 'react-icons/gi';
import FormPasswordInput from '../shared/FormPasswordInput';
import { handleErrorMessage } from '../../utils';

const ResetPassword = () => {
    const { t } = useTranslation();
    const { token } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isOkMessageOpen, setIsOkMessageOpen] = useState(false);
    const [passwordRequirements, setPasswordRequirements] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false
    });
    const [isSubmited, setIsSubmited] = useState(false);

    const methods = useForm();

    const onSubmit = async (data) => {
        if (data.password !== data.passwordconfirm) return methods.setError('passwordconfirm', { type: 'custom', message: 'confirm' });
        setIsLoading(true);
        const res = await apiRequest.auth.restartPassword({ token, ...data });
        if (res?.success) {
            setIsOkMessageOpen(true);
        } else {
            const errorMessage = handleErrorMessage(res?.error);
            toast.error(errorMessage);
        }
        return setIsLoading(false);
    };

    const handleCloseModal = () => {
        navigate('/');
    };

    const handleCheckRequirements = (e) => {
        setIsSubmited(false);
        const inputValue = e.target.value;
        const uppercase = /[A-Z]/.test(inputValue);
        const lowercase = /[a-z]/.test(inputValue);
        const number = /\d/.test(inputValue);
        const length = inputValue.length >= 7;
        const special = /[@$!%*?&]/.test(inputValue);

        setPasswordRequirements({
            uppercase, lowercase, number, length, special
        });
    };

    useEffect(() => {
    // eliminar token de las cookies en el caso que haya uno guardado
        setCookie('token_piezas_recomotor', '', -1);
    }, []);

    return (
        <div className='reset-password'>
            <img src={logo} alt='recomotor' className='reset-password__logo' />
            <div className='reset-password__container'>
                <h1 className='reset-password__container--title'>{t('reset-password.title')}</h1>
                <FormProvider {...methods} >
                    <form
                        className='reset-password__container--form'
                        onSubmit={methods.handleSubmit(onSubmit)}>
                        <FormPasswordInput
                            name='password'
                            label={t('reset-password.form.nueva')}
                            className="reset-password__container--form"
                            onChange={handleCheckRequirements}
                        />
                        <FormPasswordInput
                            name='passwordconfirm'
                            label={t('reset-password.form.confirma')}
                            className="reset-password__container--form"
                        />
                        <div className='reset-password__container--form--list'>
                            <h4>{t('reset-password.form.pattern.title')}</h4>
                            <ul>
                                <li>{t('reset-password.form.pattern.min')} {passwordRequirements.length ? <GiCheckMark color='#215732' /> : (isSubmited ? <FaExclamation color='orange' /> : '')}</li>
                                <li>{t('reset-password.form.pattern.mayus')} {passwordRequirements.uppercase ? <GiCheckMark color='#215732' /> : (isSubmited ? <FaExclamation color='orange' /> : '')}</li>
                                <li>{t('reset-password.form.pattern.minus')} {passwordRequirements.lowercase ? <GiCheckMark color='#215732' /> : (isSubmited ? <FaExclamation color='orange' /> : '')}</li>
                                <li>{t('reset-password.form.pattern.num')} {passwordRequirements.number ? <GiCheckMark color='#215732' /> : (isSubmited ? <FaExclamation color='orange' /> : '')}</li>
                                <li>{t('reset-password.form.pattern.special')} {passwordRequirements.special ? <GiCheckMark color='#215732' /> : (isSubmited ? <FaExclamation color='orange' /> : '')}</li>
                            </ul>
                        </div>
                        <button type='submit' onClick={() => setIsSubmited(true)}>{isLoading ? <Spinner className='spinner-login'/> : t('reset-password.form.btn')}</button>
                    </form>
                </FormProvider>
            </div>
            {isOkMessageOpen && <OkMessage setIsOkMessageOpen={handleCloseModal} text={t('reset-password.success')} />}
        </div>
    );
};

export default ResetPassword;
