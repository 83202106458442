import { BiArrowBack } from 'react-icons/bi';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EachDirCard from './EachDirCard';
import { useEffect, useState } from 'react';

import './misDirecciones.scss';
import { Confirm } from '../../../shared/Alert';
import { apiRequest } from '../../../../services/api';
import SkeletonDirCard from './SkeletonDirCard';
import { toast } from 'react-toastify';
import HandleAddressModal from '../../../shared/HandleAddressModal';

const MisDirecciones = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [dirEnvio, setDirEnvio] = useState('otra');
    const [dirToDelete, setDirToDelete] = useState(null);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [direcciones, setDirecciones] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleConfirmDelete = async (result) => {
        if (result) {
            const res = await handleDelete(dirToDelete);
            if (res) {
                toast.success(t('success.addresses.1'));
            } else {
                toast.error(t('errors.addresses.1'));
            }
        }
        setOpenConfirmDelete(false);
    };

    const handleStartDelete = (direccionId) => {
        setDirToDelete(direccionId);
        setOpenConfirmDelete(true);
        window.scrollTo(0, 0);
    };

    const handleDelete = async (direccionId) => {
        const res = await apiRequest.user.deleteAddress({ addressId: direccionId });
        if (res?.success) {
            const newArrDir = direcciones?.filter(dir => dir.id !== direccionId);
            setDirecciones(newArrDir);
            return true;
        } else {
            return false;
        }
    };

    const handleOpenForm = (direccion) => {
        setDirEnvio(direccion);
        setIsOpenForm(true);
    };

    const addNewDirection = async (direccion) => {
        const res = await apiRequest.user.addNewAddress(direccion);
        if (res?.success) {
            setDirecciones([...direcciones, res.newAddress]);
            setIsOpenForm(false);
            return window.scrollTo(0, 0);
        }
        return toast.error(t('errors.1'));
    };

    const reqGetAddresses = async () => {
        setIsLoading(true);
        const res = await apiRequest.user.getAddresses();
        if (res?.success) {
            setDirecciones(res.addresses);
        } else {
            toast.error(t('errors.1'));
        }
        return setIsLoading(false);
    };

    const isAddressOutOfRange = (address) => {
        if ((address?.poblacion?.codigo_postal >= '51001' && address?.poblacion?.codigo_postal <= '51080') || // CEUTA
    (address?.poblacion?.codigo_postal >= '52001' && address?.poblacion?.codigo_postal <= '52080') || // MELILLA
    ((address?.poblacion?.codigo_postal >= '35001' && address?.poblacion?.codigo_postal <= '35660') || (address?.poblacion?.codigo_postal >= '38001' && address?.poblacion?.codigo_postal <= '38917')) || // CANARIAS
    ((address?.poblacion?.codigo_postal >= '9000-001' && address?.poblacion?.codigo_postal <= '9980-999')) // ISLAS PORTUGAL
        ) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        reqGetAddresses();
    }, []);

    return <div className='mis-direcciones'>
        <span
            className='mis-direcciones__return'
            onClick={() => navigate('/area-personal')}>
            <BiArrowBack/>
        </span>
        <h2>{t('area-personal.direcciones.title')}</h2>
        {<div className='mis-direcciones__direcciones'>
            <div>
                {!isLoading
                    ? <>
                        {direcciones.length > 0
                            ? direcciones?.map((dir, idx) => (
                                <EachDirCard key={idx}
                                    direccion={dir}
                                    handleDelete={handleStartDelete}
                                    isOutOfRange={isAddressOutOfRange(dir)}
                                />))
                            : <span className='mis-direcciones__direcciones--no-address'>{t('area-personal.direcciones.no-address')}</span>
                        }</>
                    : <div className='mis-direcciones__container-skeleton'><SkeletonDirCard isBtn={true} /></div>}
            </div>
            <button
                className='mis-direcciones__direcciones--add'
                onClick={() => handleOpenForm('otra')}><span><MdAdd/></span>{t('area-personal.direcciones.añadir')}</button>
        </div>}
        {isOpenForm && <HandleAddressModal setIsOpenModal={setIsOpenForm} addressData={dirEnvio} handleAddressData={addNewDirection} isDirFact={false} />}
        {openConfirmDelete && <Confirm
            text={t('area-personal.direcciones.confirm')}
            handleConfirm={handleConfirmDelete} />}
    </div>;
};

export default MisDirecciones;
