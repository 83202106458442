import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MdCancel } from 'react-icons/md';
import './formAddress.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { apiRequest } from '../../../services/api';
import Spinner from '../Spinner';
import { toast } from 'react-toastify';
import GooglePlacesAutocomplete from '../GooglePlacesAutocomplete';
import FormInput from '../FormInput';
import { handleValidCp, handleValidPhone, validarNIF, validatePostalCode } from '../../../utils';

const FormAddress = ({ address, uploadDirection, isDirFact }) => {
    const { t } = useTranslation();
    const cpRef = useRef(null);
    const poblacionRef = useRef(null);
    const areaRef = useRef(null);
    const methods = useForm({
        defaultValues: {
            nombre: address?.nombre || '',
            telefono: address?.telefono || '',
            direccion: address?.direccion_facturacion || '',
            codigo_postal: address?.codigo_postal_facturacion || '',
            poblacion: address?.poblacion_facturacion || '',
            area: address?.area_facturacion || '',
            pais_id: address?.pais_facturacion?.id || 1,
            cif: address?.cif,
            email: address?.email,
            piso: ''
        }
    });

    const { handleSubmit, setValue, getValues, formState: { errors }, clearErrors, watch, setError } = methods;
    const [paises, setPaises] = useState(null);
    const [currentPaisId, setCurrentPaisId] = useState(1);
    const [b2b, setB2b] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getPaisesReq();
    }, []);

    const onSaveData = async (data) => {
        if (isDirFact && !validarNIF(data.cif)) {
            return setError('cif', { type: 'valid', message: 'valid' });
        }
        if (!validatePostalCode(data.codigo_postal, (Number(data.pais_id) === 1 || Number(currentPaisId) === 1) ? 'ES' : 'PT')) {
            return setError('codigo_postal', { type: 'valid', message: 'valid' });
        }
        if (isLoading) return;
        setIsLoading(true);

        // update invoicing address
        if (isDirFact) {
            data.direccion_facturacion = data.direccion + (data?.piso ? ` ${data?.piso}` : '');
            delete data.direccion;
            data.codigo_postal_facturacion = data.codigo_postal;
            delete data.codigo_postal;
            data.poblacion_facturacion = data.poblacion;
            delete data.poblacion;
            data.area_facturacion = data.area;
            delete data.area;
            data.pais_facturacion_id = data.pais_id || currentPaisId;
            delete data.pais_id;

            await uploadDirection(data, paises.find(pais => pais.id === (data.pais_facturacion_id || currentPaisId)));
            return setIsLoading(false);
        }

        // SHIPPING ADDRESS

        // check outside peninsula
        const areaLowCase = data.area.toLowerCase();
        if (((Number(data.pais_id) !== 1 && Number(data.pais_id) !== 10) ||
            (Number(data.pais_id) === 1 && data.codigo_postal >= '51001' && data.codigo_postal <= '51080') || // CEUTA
            (Number(data.pais_id) === 1 && data.codigo_postal >= '52001' && data.codigo_postal <= '52080') || // MELILLA
            ((Number(data.pais_id) === 1 && data.codigo_postal >= '35001' && data.codigo_postal <= '35660') || (Number(data.pais_id) === 1 && data.codigo_postal >= '38001' && data.codigo_postal <= '38917')) || // CANARIAS
            ((Number(data.pais_id) === 10 && (data?.codigo_postal >= '9000-001' && data?.codigo_postal <= '9980-999'))) || // ISLAS PORTUGAL
            (areaLowCase.includes('ceuta') || areaLowCase.includes('melilla') || areaLowCase.includes('canarias') || areaLowCase.includes('madeira') || areaLowCase.includes('azores') || areaLowCase.includes('açores'))
        )) {
            setIsLoading(false);
            return toast.error(t('errors.fuera-rango'));
        }

        if (data.piso) {
            data.direccion = data.direccion + ` ${data.piso}`;
        }
        // create shipping address
        await uploadDirection(data);
        setIsLoading(false);
    };

    useEffect(() => {
        setCurrentPaisId(address?.pais_facturacion?.id);
        isDirFact && setValue('b2b', address?.b2b || false);
        if (isDirFact && address?.b2b) setB2b(true);
    }, [address]);

    const getPaisesReq = async () => {
        const res = await apiRequest.user.getPaises();
        if (res?.success) {
            return setPaises(res.paises);
        }
        return toast.error(t('errors.1'));
    };

    const handleChangePais = (e) => {
        setCurrentPaisId(e.target.value);
        setValue('pais_id', e.target.value);
    };

    const handleChangeDireccion = (direccion) => {
        Object.keys(direccion).forEach(function (key) {
            setValue(key, direccion[key]);
            if (direccion[key]) clearErrors(key);
        });
        cpRef.current.value = direccion.codigo_postal;
        poblacionRef.current.value = direccion.poblacion;
        areaRef.current.value = direccion.area;
    };

    const handleErrorDireccion = (e) => {
        if (!getValues(e.target.name)) setError('direccion', 'select-option');
    };

    const handleB2b = (e) => {
        setB2b(e.target.value);
        setValue('b2b', e.target.value === 'true');
    };

    return (
        <FormProvider {...methods} >
            <form className={'form-dir-envio'} onSubmit={handleSubmit(onSaveData)}>
                <h4>{isDirFact ? t('area-personal.datos.edit') : t('carrito.envio.form.title')}</h4>
                <FormInput
                    className='form-dir-envio'
                    classNameSize={isDirFact ? '-small-xs' : ''}
                    name='nombre'
                    pattern={{ value: /^[\p{L}]/u }}
                    onChange={() => clearErrors('nombre')}
                />
                {isDirFact && <FormInput
                    className='form-dir-envio'
                    name='cif'
                    classNameSize='-small-xs'
                    pattern={{ value: /^[0-9a-zA-Z]+$/, message: 'valid' }}
                    isRequired={false}
                    minLength={{ value: 9, message: 'minLength' }}
                />}
                {isDirFact &&
                (
                    <div className="form-dir-envio__div-small-xs xxs">
                        <label htmlFor="dir-pais">
                            {t('carrito.envio.form.b2b-title')}
                            <select
                                className='form-dir-envio__label--select'
                                value={b2b}
                                onChange={handleB2b}>
                                <option value={true}>
                                    {t('carrito.envio.form.b2b')}
                                </option>
                                <option value={false}>
                                    {t('carrito.envio.form.b2b-part')}
                                </option>
                            </select>
                        </label>
                    </div>
                )}
                {isDirFact && <FormInput
                    className='form-dir-envio'
                    classNameSize={isDirFact ? '-md-size' : ''}
                    name='email'
                    type='email'
                    pattern={{
                        value: /\S+@\S+\.\S+/,
                        message: 'formato'
                    }}
                    isDisabled={true}
                />}
                <FormInput
                    className='form-dir-envio'
                    classNameSize='-md-size'
                    name='telefono'
                    pattern={{ value: /^\S.*[0-9]+$/ }}
                    minLength={{ value: 9, message: 'minLength' }}
                    onKeyDown={handleValidPhone} />
                <div className='form-dir-envio__div-small'>
                    <label className='form-dir-envio__label'>
                        {t('input-form.name.pais')}
                        {paises
                            ? <select
                                className='form-dir-envio__label--select'
                                value={currentPaisId}
                                onChange={handleChangePais}>
                                {paises.map(pais => <option key={pais?.id} value={pais?.id}>{pais?.nombre}</option>)}
                            </select>
                            : <div className='skeleton-input'><span className='skeleton-input__span'></span></div>}
                    </label>
                    {errors?.pais_id && <span role='alert'><MdCancel/> {t('carrito.envio.form.errors.pais')}</span>}
                </div>
                <div className='form-dir-envio__div-large'>
                    <GooglePlacesAutocomplete onSelectResult={handleChangeDireccion}
                        defaultValue={getValues('direccion')}
                        country={watch('pais_id') ? paises?.find(p => p.id === Number(getValues('pais_id')))?.codigo : 'ES'}
                        disabled={false}
                    />
                </div>
                <FormInput
                    className='form-dir-envio'
                    name='piso'
                    isRequired={false}
                    classNameSize='-md-size'
                />
                <FormInput
                    className='form-dir-envio'
                    name='codigo_postal'
                    classNameSize='-md-size'
                    isRequired={!isDirFact}
                    maxLength={{ value: 10, message: 'maxLength' }}
                    onClick={handleErrorDireccion}
                    onKeyDown={handleValidCp}
                    inputRef={cpRef}
                />
                <FormInput
                    className='form-dir-envio'
                    name={'poblacion'}
                    isRequired={!isDirFact}
                    maxLength={{ value: 150, message: 'maxLength' }}
                    pattern={{ value: /^[A-Za-z]/ }}
                    inputRef={poblacionRef}
                />
                <FormInput
                    className='form-dir-envio'
                    name={'area'}
                    isRequired={!isDirFact}
                    maxLength={{ value: 150, message: 'maxLength' }}
                    pattern={{ value: /^[A-Za-z]/ }}
                    onClick={handleErrorDireccion}
                    inputRef={areaRef}
                />

                <div className='form-dir-envio__button'>
                    <button disabled={isLoading} className='form-dir-envio__button--btn' type='submit'>{isLoading ? <Spinner className='spinner-form-dir-envio'/> : t('carrito.envio.form.guardar')}</button>
                </div>
            </form>
        </FormProvider>
    );
};

FormAddress.propTypes = {
    address: PropTypes.any,
    uploadDirection: PropTypes.func,
    isDirFact: PropTypes.bool
};
export default FormAddress;
