import config from '../config';
import { thunk } from 'redux-thunk';
import { authReducer } from './reducers/authReducer';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { cartReducer } from './reducers/cartReducer';
import { favoritesReducer } from './reducers/favoritesReducer';
import { filtersReducer } from './reducers/filtersReducer';

const reducers = combineReducers({
    auth: authReducer,
    cart: cartReducer,
    favorites: favoritesReducer,
    filters: filtersReducer
});

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    devTools: config.app.nodeEnv !== 'production'
});
